// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import GA4React from 'ga-4-react'
import ReactGA from 'react-ga'
// @ts-ignore
import TagManager from 'react-gtm-module'

// data
import {
    api_url_portfolio_page_detail_site,
    reduxAuthStateSite,
    reduxCacheSetPageFontsSite,
    reduxCacheSetPageSite,
    reduxCacheStateSite,
    reduxModalGaInitSite,
    reduxModalGtmInitSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    PortfolioDetailSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'


// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// main
export function getPageDataSite({
    axiosCancelToken,
    component,
    detailId,
    dispatch,
    errorStatus,
    hubComponentType,
    hubContentSlug,
    onFinished,
    pageSlug,
    portfolioObject,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    scrollToTop,
    setErrorStatus,
    setIsLoading,
    setPageObject,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    detailId: string | undefined
    dispatch: React.Dispatch<any>
    errorStatus: errorStatusTypeSite
    hubComponentType?: string
    hubContentSlug?: string
    onFinished?: (e: any) => void
    pageSlug: string
    portfolioObject: PortfolioDetailSiteSerializer
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    scrollToTop: any | undefined
    setErrorStatus: React.Dispatch<errorStatusTypeSite>
    setIsLoading: React.Dispatch<boolean>
    setPageObject: any
}) {

    setErrorStatus({})

    if (scrollToTop) scrollToTop()

    let pageApiUrl = getApiUrlSite(`${api_url_portfolio_page_detail_site}${pageSlug || portfolioObject.home_page_slug}/?portfolio_id=${portfolioObject.id}`, reduxModalSite)
    if (detailId) pageApiUrl += `&detail_id=${detailId?.split('-')[0]}`
    pageApiUrl += `&language_id=${reduxModalSite.languageId}`
    if (reduxModalSite.currencyId) pageApiUrl += `&currency_id=${reduxModalSite.currencyId}`
    if (hubContentSlug) pageApiUrl += `&hub_content_slug=${hubContentSlug}`
    if (hubComponentType) pageApiUrl += `&component_type=${hubComponentType}`

    if (reduxCacheSite.pages?.[pageApiUrl]) {
        setPageObject(reduxCacheSite.pages[pageApiUrl])
        if (onFinished) onFinished(reduxCacheSite.pages[pageApiUrl])
        return
    }

    if (process.env.NODE_ENV === 'development') console.log(pageApiUrl)

    axios({
        cancelToken: axiosCancelToken?.token,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: pageApiUrl,
    })
        .then((pageResponse) => {
            if (process.env.NODE_ENV === 'development') console.log(pageResponse)
            if (scrollToTop) scrollToTop()
            setPageObject(pageResponse.data)
            if (onFinished) onFinished(pageResponse.data)
            dispatch(reduxCacheSetPageSite(pageApiUrl, pageResponse.data))
            if (pageResponse.data.fonts) {
                dispatch(reduxCacheSetPageFontsSite(pageResponse.data.fonts))
            }
            if (process.env.NODE_ENV === 'production' && reduxModalSite.cookieConsent?.isAccepted && portfolioObject.google_analytics) {
                if (portfolioObject.google_analytics.substring(0, 3) === 'UA-') {
                    if (!reduxModalSite.gaInit) {
                        ReactGA.initialize(portfolioObject.google_analytics)
                        dispatch(reduxModalGaInitSite())
                    }
                    ReactGA.pageview(window.location.pathname)
                } else if (portfolioObject.google_analytics.substring(0, 2) === 'G-') {
                    if (!reduxModalSite.gaInit) {
                        const ga4react = new GA4React(portfolioObject.google_analytics)
                        ga4react.initialize().then((ga4) => {
                            ga4.pageview(window.location.pathname)
                            dispatch(reduxModalGaInitSite(ga4))
                        }, (err) => {
                            console.error(err)
                        })
                    } else {
                        try {
                            reduxModalSite.gaInit.pageview(window.location.pathname)
                        } catch {
                            console.log('error', reduxModalSite.gaInit)
                        }
                    }
                }
            }
            if (process.env.NODE_ENV === 'production' && reduxModalSite.cookieConsent?.isAccepted && portfolioObject.google_tag_manager) {
                if (portfolioObject.google_tag_manager.substring(0, 3) === 'GTM-') {
                    if (!reduxModalSite.gtmInit) {
                        TagManager.initialize({
                            gtmId: portfolioObject.google_tag_manager
                        })
                        dispatch(reduxModalGtmInitSite())
                    }
                }
            }
        })
        .catch((pageError) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPageDataSite')
            if (pageError.response?.status) {
                setErrorStatus({
                    ...errorStatus,
                    page: pageError.response.status,
                })
            }
            axiosErrorHandlerSite({
                apiUrl: pageApiUrl,
                component,
                dispatch,
                error: pageError,
                reduxAuth,
                reduxModalSite,
                reference: 'getPageDataSite',
                skipAlertStatusCode: [403, 404],
                skipSendErrorStatusCode: [403, 404],
            })
        })
}
