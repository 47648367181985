// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    ListSpinnerSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ContentBlockSite,
    PrivateFilterSite,
} from 'pages'

// serializers
import {
    PortfolioHubContentFilterListSiteSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getHubContentDetailSite,
    parseQuerySite,
} from 'services'

// props
type PrivateMapListSiteProps = {
    clusterIds: number[]
    devId: string | undefined
    filterInfo: {
        filterSet: PortfolioHubContentFilterListSiteSerializer['filters'],
        tabSlug: string
    } | undefined
    onFilterChange: any
    tabSlug: string
}

// main
export const PrivateMapListSite: React.FC<PrivateMapListSiteProps> = React.memo(({
    clusterIds,
    devId,
    filterInfo,
    onFilterChange,
    tabSlug,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource>()
    const [currencyId, setCurrencyId] = useState<number | undefined>(reduxModalSite.currencyId)
    const [isLoading, setIsLoading] = useState(false)
    const [languageId, setLanguageId] = useState<number>(reduxModalSite.languageId)
    const [pageContentHeader, setPageContentHeader] = useState<PortfolioPageContentListSiteSerializer[]>()
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[]>()

    useEffect(() => {
        getPortfolioData()
    }, [
        clusterIds,
    ])

    useEffect(() => {
        if (languageId !== reduxModalSite.languageId) {
            getPortfolioData()
            setLanguageId(reduxModalSite.languageId)
        } else if (currencyId !== reduxModalSite.currencyId) {
            getPortfolioData()
            setCurrencyId(reduxModalSite.currencyId)
        }
    }, [
        reduxModalSite.currencyId,
        reduxModalSite.languageId,
    ])

    function getPortfolioData() {
        try {
            if (tabSlug) {
                getHubContentDetailSite({
                    axiosCancelToken,
                    clusterIds,
                    component: 'PrivateMapListSite',
                    componentType: 'map',
                    contentSlug: tabSlug,
                    dispatch,
                    location,
                    noSearch: true,
                    portfolioId: reduxCacheSite.portfolio!.id!,
                    reduxAuth,
                    reduxModalSite,
                    setAxiosCancelToken,
                    setIsLoading,
                    setPageContentHeader,
                    setPageContentItems,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateMapListSite',
                'getPortfolioData',
            ))
        }
    }

    // function onSearchNext(isVisible: boolean) {
    // 	try {
    // 		if (listState.disableInfiniteScroll) return
    // 		if (isVisible) {
    // 			onGetListData(listState.nextUrl, false)
    // 		}
    // 	} catch (error) {
    // 		dispatch(reduxModalErrorEventHandlerSite(
    // 			error,
    // 			'PrivateMapListSite',
    // 			'onSearchNext',
    // 		))
    // 	}
    // }

    return (
        <div
            className={`private-map-list mo-hidden-vertical-scrollbar${reduxModalSite.isWindows} ${deviceType}`}
            style={{
                height: `calc(100vh - ${reduxModalSite.navbarHeight}px - 50px)`,
            }}
        >
            {filterInfo?.filterSet?.length! > 0 && (
                <div className={`hml-filter-wrap ${deviceType}`}>
                    <PrivateFilterSite
                        filterInfo={filterInfo!}
                        languageId={reduxModalSite.languageId}
                        onChange={(e: string) => onFilterChange(e)}
                        portfolioId={reduxCacheSite.portfolio?.id!}
                    />
                </div>
            )}
            {!isLoading && pageContentHeader?.[0] && !parseQuerySite(location.search)?.query && !parseQuerySite(location.search)?.filter_query && (
                <ContentBlockSite
                    detailId={undefined}
                    devId={devId}
                    isEditHovered={false}
                    linkColor={undefined}
                    object={pageContentHeader[0]}
                    pageSlug='private'
                    stylesEdit={undefined}
                />
            )}
            {!isLoading && pageContentItems?.filter(obj => obj.active === true).map((val) => (
                <ContentBlockSite
                    key={val.id}
                    detailId={undefined}
                    devId={devId}
                    hubComponentType='map'
                    hubContentSlug={tabSlug}
                    isEditHovered={false}
                    linkColor={undefined}
                    object={val}
                    pageSlug='private'
                    stylesEdit={undefined}
                />
            ))}
            <ListSpinnerSite isLoading={isLoading} />
        </div>
    )
})
