import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import debounce from 'lodash.debounce'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import AsyncSelect from 'react-select/async'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldHelperSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSiteV2,
    getAxiosHeadersSite,
} from 'services'

// props
type TemplateBlock797LocationSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldHelperSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: any
}

// main
export const TemplateBlock797LocationSite: React.FC<TemplateBlock797LocationSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    handleInputChange,
    isEditHovered,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [newValue, setNewValue] = useState<any>()

    useEffect(() => {
        if (value) {
            setNewValue({
                place_id: value[0]?.place_id,
                description: value[0]?.description,
            })
        } else {
            setNewValue(null)
        }
    }, [
        value,
    ])

    async function getOptions(inputValue: string) {
        const getUrl = getApiUrlSiteV2(`main/helper/googleplace/autocomplete/?search=${inputValue}`, reduxModalSite)
        if (process.env.NODE_ENV === 'development') console.log(getUrl)
        try {
            const response = await fetch(getUrl, reduxAuth?.axiosConfig)
            const json = await response.json()
            return json
        } catch (error) {
            axiosErrorHandlerSite({
                apiUrl: getUrl,
                component: 'TemplateBlock797LocationSite',
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'getOptions',
            })
        }
    }

    const debouncedGetOptions = useCallback(
        debounce((inputValue: string, callback: (options: any) => void) => {
            getOptions(inputValue).then(callback)
        }, 500),
        []
    )

    const promiseOptions = (inputValue: string) =>
        new Promise(resolve => {
            debouncedGetOptions(inputValue, resolve)
        })

    function onInputChange(value: any) {
        try {
            handleInputChange(formInputObj?.name!, value, formInputObj?.label!, formInputObj?.stripe_field_type)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797LocationSite',
                'onInputChange',
            ))
        }
    }

    function getPlaceDetail(e: any) {
        try {
            if (!e?.place_id) {
                onInputChange('')
            } else {
                const getUrl = getApiUrlSiteV2(`main/helper/googleplace/detail/?place_id=${e.place_id}&description=${e.description}`, reduxModalSite)
                if (process.env.NODE_ENV === 'development') console.log(getUrl)
                axios({
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'get',
                    url: getUrl,
                })
                    .then((response) => {
                        onInputChange(response.data)
                    })
                    .catch((error) => {
                        axiosErrorHandlerSite({
                            apiUrl: getUrl,
                            component: 'TemplateBlock797LocationSite',
                            dispatch,
                            error,
                            reduxAuth,
                            reduxModalSite,
                            reference: 'getPlaceDetail',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797LocationSite',
                'getPlaceDetail',
            ))
        }
    }

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            padding: '0 8px',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: stylesNew?.background || 'transparent',
            border: stylesNew?.borderWidth ? 'undefined' : 'none',
            borderColor: stylesNew?.borderColor,
            borderStyle: stylesNew?.borderStyle,
            borderWidth: stylesNew?.borderWidth,
            borderRadius: stylesNew?.borderRadius,
            color: stylesNew?.color,
            fontSize: stylesNew?.fontSize,
            padding: stylesNew?.padding,
        }),
        dropdownIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            padding: '0 0 0 8px',
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            ...provided,
            margin: 0,
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
            margin: 0,
            padding: 0,
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            ':hover': {
                ...provided[':hover'],
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
            margin: 0,
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            padding: 0,
        }),
    }

    return (
        <AsyncSelect
            cacheOptions
            className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            getOptionLabel={option => option.description}
            getOptionValue={(option: any) => option.place_id}
            id={blockId}
            isClearable={true}
            // @ts-ignore
            loadOptions={promiseOptions}
            name={formInputObj?.name}
            noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[9754] : reduxText[9755]}
            onChange={getPlaceDetail}
            placeholder={formInputObj?.placeholder === 'true' ? (formInputObj?.translations?.find((obj) => obj.language_id === reduxModalSite.languageId)?.value || formInputObj?.label) : ''}
            styles={customStyles}
            tabIndex={tabIndex}
            value={newValue}
        />
    )
})
