// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    Location,
} from 'history'
import slugify from 'slugify'

// data
import {
    api_url_portfolio_detail_id_site,
    api_url_portfolio_detail_site,
    reduxAuthStateSite,
    reduxCacheSetPortfolioSite,
    reduxCacheStateSite,
    reduxModaSetDeviceTypeSite,
    reduxModalSetCurrencyIdSite,
    reduxModalSetLanguageIdSite,
    reduxModalSlaaskInitSite,
    reduxModalStateSite,
    reduxSalesResetSite,
} from 'data'

// serializers
import {
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    getHostOriginSite,
    getHubContentFilterListDataSite,
    getHubContentListDataSite,
    getPageDataSite,
    parseQuerySite,
} from 'services'

// main
export function getPortfolioHubDataSite({
    axiosCancelToken,
    component,
    component_type,
    contentSlug,
    devId,
    dispatch,
    errorStatus,
    forceRefresh,
    location,
    needFilter,
    onFinished,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    setErrorStatus,
    setHasSearchPage,
    setHubFilter,
    setHubObject,
    setIsLoading,
    setPageObject,
}: {
    axiosCancelToken: CancelTokenSource | undefined,
    component: string,
    component_type: string | undefined,
    contentSlug: string,
    devId: string | undefined,
    dispatch: React.Dispatch<any>,
    errorStatus: errorStatusTypeSite,
    forceRefresh?: boolean,
    location: Location,
    needFilter: boolean,
    onFinished: (e: any) => void,
    reduxAuth: reduxAuthStateSite,
    reduxCacheSite: reduxCacheStateSite,
    reduxModalSite: reduxModalStateSite,
    setErrorStatus: React.Dispatch<errorStatusTypeSite>,
    setHasSearchPage: React.Dispatch<boolean>,
    setHubFilter: React.Dispatch<any>,
    setHubObject: React.Dispatch<any>,
    setIsLoading: React.Dispatch<boolean>,
    setPageObject: React.Dispatch<any>,
}) {

    setErrorStatus({})

    setIsLoading(true)
    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getPortfolioHubDataSite')

    if (!forceRefresh && reduxCacheSite.portfolio?.id) {
        getPageDataSite({
            axiosCancelToken,
            component,
            detailId: undefined,
            dispatch,
            errorStatus,
            hubComponentType: component_type,
            hubContentSlug: contentSlug,
            onFinished,
            pageSlug: 'hub',
            portfolioObject: reduxCacheSite.portfolio,
            reduxAuth,
            reduxCacheSite,
            reduxModalSite,
            scrollToTop: undefined,
            setErrorStatus,
            setIsLoading,
            setPageObject,
        })
        if (needFilter) {

            getHubContentListDataSite({
                axiosCancelToken,
                component,
                dispatch,
                portfolioObject: reduxCacheSite.portfolio,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                setHasSearchPage,
                setHubObject,
            })

            getPageDataSite({
                axiosCancelToken,
                component,
                detailId: undefined,
                dispatch,
                errorStatus,
                hubComponentType: component_type,
                hubContentSlug: contentSlug,
                onFinished,
                pageSlug: 'hub',
                portfolioObject: reduxCacheSite.portfolio,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop: undefined,
                setErrorStatus,
                setIsLoading,
                setPageObject,
            })

            getHubContentFilterListDataSite({
                axiosCancelToken,
                component,
                dispatch,
                portfolioObject: reduxCacheSite.portfolio,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                setHubFilter,
            })

        }
    } else {
        const hostOriginSite = getHostOriginSite()
        const portfolioId = devId?.split('-')[0]
        const profileId = devId?.split('-')[1]
        const deviceType = devId?.split('-')[2] || 'web'

        let portfolioApiUrl = ''
        if (hostOriginSite === 'demo') {
            portfolioApiUrl = getApiUrlSite(`${api_url_portfolio_detail_id_site}${portfolioId}/?profile_id=${profileId}`, reduxModalSite)
            dispatch(reduxModaSetDeviceTypeSite(`is-${deviceType}`))
        } else if (hostOriginSite === 'site') {
            portfolioApiUrl = getApiUrlSite(`${api_url_portfolio_detail_id_site}${portfolioId}/`, reduxModalSite)
        } else {
            portfolioApiUrl = getApiUrlSite(`${api_url_portfolio_detail_site}${slugify(window.location.hostname, { remove: /[*+~.()'"!:@]/g, lower: true })}/`, reduxModalSite)
        }
        if ((portfolioApiUrl).includes('?')) {
            portfolioApiUrl += `&language_id=${reduxModalSite.languageId}`
        } else {
            portfolioApiUrl += `?language_id=${reduxModalSite.languageId}`
        }
        if (reduxModalSite.currencyId) portfolioApiUrl += `&currency_id=${reduxModalSite.currencyId}`

        const searchParams = parseQuerySite(location.search)
        const deviceTypeSite = searchParams.deviceType

        if (deviceTypeSite) {
            dispatch(reduxModaSetDeviceTypeSite(deviceTypeSite))
        }

        if (process.env.NODE_ENV === 'development') console.log(portfolioApiUrl)

        axios({
            cancelToken: axiosCancelToken?.token,
            headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
            method: 'get',
            url: portfolioApiUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch(reduxCacheSetPortfolioSite(portfolioApiUrl, response.data))

                if (response.data.coming_soon && hostOriginSite === 'prod') {
                    return
                }

                const languageCodeArray: number[] = []
                response.data.languages?.map((val: any) => {
                    languageCodeArray.push(val.id)
                })
                if (languageCodeArray.length > 0 && !languageCodeArray.includes(reduxModalSite.languageId!)) {
                    dispatch(reduxModalSetLanguageIdSite(response.data.languages[0].id))
                    return
                }

                if (response.data.is_multi_currency) {
                    const currencyCodeArray: number[] = []
                    response.data.currencies?.map((val: any) => {
                        currencyCodeArray.push(val.id)
                    })
                    if (currencyCodeArray.length > 0 && !currencyCodeArray.includes(reduxModalSite.currencyId!)) {
                        dispatch(reduxModalSetCurrencyIdSite(response.data.currency.id))
                        dispatch(reduxSalesResetSite())
                        return
                    }
                } else if (reduxModalSite.currencyId) {
                    dispatch(reduxModalSetCurrencyIdSite(undefined))
                    dispatch(reduxSalesResetSite())
                    return
                }

                getHubContentListDataSite({
                    axiosCancelToken,
                    component,
                    dispatch,
                    portfolioObject: response.data,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    setHasSearchPage,
                    setHubObject,
                })

                getPageDataSite({
                    axiosCancelToken,
                    component,
                    detailId: undefined,
                    dispatch,
                    errorStatus,
                    hubComponentType: component_type,
                    hubContentSlug: contentSlug,
                    onFinished,
                    pageSlug: 'hub',
                    portfolioObject: response.data,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    scrollToTop: undefined,
                    setErrorStatus,
                    setIsLoading,
                    setPageObject,
                })

                getHubContentFilterListDataSite({
                    axiosCancelToken,
                    component,
                    dispatch,
                    portfolioObject: response.data,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    setHubFilter,
                })

                if (response.data.slaask_api_key && !reduxModalSite.slaaskApiKey) {
                    dispatch(reduxModalSlaaskInitSite(response.data.slaask_api_key))
                }
            })
            .catch((error) => {
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPortfolioHubDataSite')
                if ([403, 404].includes(error.response?.status)) {
                    setErrorStatus({
                        ...errorStatus,
                        portfolio: error.response.status,
                    })
                } else {
                    axiosErrorHandlerSite({
                        apiUrl: portfolioApiUrl,
                        component,
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getPortfolioHubDataSite',
                    })
                }
            })
    }
}
