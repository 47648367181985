// react components
import React from 'react'

// pages
import {
    TemplateBlock763Site,
    TemplateBlock779Site,
    TemplateBlock780Site,
    TemplateBlock781Site,
    TemplateBlock782Site,
    TemplateBlock783Site,
    TemplateBlock784Site,
    TemplateBlock785Site,
    TemplateBlock786Site,
    TemplateBlock787Site,
    TemplateBlock789Site,
    TemplateBlock790Site,
    TemplateBlock791Site,
    TemplateBlock792Site,
    TemplateBlock793Site,
    TemplateBlock794Site,
    TemplateBlock795Site,
    TemplateBlock796Site,
    TemplateBlock797Site,
    TemplateBlock798Site,
    TemplateBlock799Site,
    TemplateBlock800Site,
    TemplateBlock801Site,
    TemplateBlock802Site,
    TemplateBlock803Site,
    TemplateBlock804Site,
    TemplateBlock805Site,
    TemplateBlock806Site,
    TemplateBlock808Site,
    TemplateBlock809Site,
    TemplateBlock810Site,
    TemplateBlock811Site,
    TemplateBlock812Site,
    TemplateBlock814Site,
    TemplateBlock815Site,
    TemplateBlock816Site,
    TemplateBlock817Site,
    TemplateBlock818Site,
    TemplateBlock819Site,
    TemplateBlock822Site,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// props
type ContentBlockSiteProps = {
    contentIdsAll?: any[]
    detailId: string | undefined
    devId: string | undefined
    hubComponentType?: string
    hubContentSlug?: string
    inAbout?: any
    infiniteContentId?: number
    isEditHovered: boolean
    isInDetailPage?: boolean
    linkColor: string | undefined
    object: PortfolioPageContentListSiteSerializer
    pageNumberNew?: number
    pageSlug: string | undefined
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const ContentBlockSite: React.FC<ContentBlockSiteProps> = React.memo(({
    contentIdsAll,
    detailId,
    devId,
    hubComponentType,
    hubContentSlug,
    inAbout,
    infiniteContentId,
    isEditHovered,
    isInDetailPage,
    linkColor,
    object,
    pageNumberNew,
    pageSlug,
    stylesEdit,
}) => {

    if (!object.layout) return null
    if (!object.content) return null

    let blockId = `content-section-${object.id}`
    if (object.layout_type === 'n') {
        blockId = `navbar-section-${object.id}`
    } else if (object.layout_type === 'f') {
        blockId = `footer-section-${object.id}`
    } else {
        blockId = `content-new-section-${object.id}`
    }

    switch (object.layout) {
        case 763:
            return (
                <TemplateBlock763Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                />
            )
        case 779:
            return (
                <TemplateBlock779Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    linkColor={linkColor}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 780:
            return (
                <TemplateBlock780Site
                    blockId={blockId}
                    content={object.content}
                    contentIdsAll={contentIdsAll}
                    detailId={detailId}
                    devId={devId}
                    hubComponentType={hubComponentType}
                    hubContentSlug={hubContentSlug}
                    inAbout={inAbout}
                    infiniteContentId={infiniteContentId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    linkColor={linkColor}
                    object={object}
                    pageNumberNew={pageNumberNew}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 781:
            return (
                <TemplateBlock781Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    object={object}
                    pageNumberNew={pageNumberNew}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 782:
            return (
                <TemplateBlock782Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 783:
            return (
                <TemplateBlock783Site
                    blockId={blockId}
                    content={object.content}
                    formInfo={undefined}
                    formValue={undefined}
                    isEditHovered={isEditHovered}
                    object={object}
                    setFormValue={undefined}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 784:
            return (
                <TemplateBlock784Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageNumberNew={pageNumberNew}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 785:
            return (
                <TemplateBlock785Site
                    blockId={blockId}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 786:
            return (
                <TemplateBlock786Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 787:
            return (
                <TemplateBlock787Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 789:
            return (
                <TemplateBlock789Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 790:
            return (
                <TemplateBlock790Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 791:
            return (
                <TemplateBlock791Site
                    blockId={blockId}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 792:
            return (
                <TemplateBlock792Site
                    blockId={blockId}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 793:
            return (
                <TemplateBlock793Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 794:
            return (
                <TemplateBlock794Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 795:
            return (
                <TemplateBlock795Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 796:
            return (
                <TemplateBlock796Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 797:
            return (
                <TemplateBlock797Site
                    blockId={blockId}
                    formInfo={undefined}
                    formValue={undefined}
                    index={1}
                    isEditHovered={isEditHovered}
                    object={object}
                    setFormValue={undefined}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 798:
            return (
                <TemplateBlock798Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 799:
            return (
                <TemplateBlock799Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 800:
            return (
                <TemplateBlock800Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    linkColor={linkColor}
                    object={object}
                    pageSlug={pageSlug}
                />
            )
        case 801:
            return (
                <TemplateBlock801Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 802:
            return (
                <TemplateBlock802Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 803:
            return (
                <TemplateBlock803Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 804:
            return (
                <TemplateBlock804Site
                    blockId={blockId}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 805:
            return (
                <TemplateBlock805Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 806:
            return (
                <TemplateBlock806Site
                    blockId={blockId}
                    content={object.content}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 808:
            return (
                <TemplateBlock808Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 809:
            return (
                <TemplateBlock809Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 810:
            return (
                <TemplateBlock810Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 811:
            return (
                <TemplateBlock811Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 812:
            return (
                <TemplateBlock812Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 814:
            return (
                <TemplateBlock814Site
                    blockId={blockId}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 815:
            return (
                <TemplateBlock815Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 816:
            return (
                <TemplateBlock816Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 817:
            return (
                <TemplateBlock817Site
                    blockId={blockId}
                    content={object.content}
                    detailId={detailId}
                    devId={devId}
                    isEditHovered={isEditHovered}
                    isInDetailPage={isInDetailPage}
                    object={object}
                    pageSlug={pageSlug}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 818:
            return (
                <TemplateBlock818Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 819:
            return (
                <TemplateBlock819Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        case 822:
            return (
                <TemplateBlock822Site
                    blockId={blockId}
                    content={object.content}
                    isEditHovered={isEditHovered}
                    object={object}
                    styles={object.styles}
                    stylesEdit={stylesEdit}
                />
            )
        default:
            return null
    }
})
