// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    IonSearchbar,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    IconBlockSite,
    LoaderSite,
    SeoBlockSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalClearSearchSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ContentBlockSite,
    PrivateFilterSite,
    PrivateMapSite,
    PrivateSideMenuTabWeb,
    NotFoundPortfolioSite,
} from 'pages'

// serializers
import {
    PageDetailSiteSerializer,
    PortfolioHubContentFilterListSiteSerializer,
    PortfolioHubContentListSiteSerializer,
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    checkPorfolioUserGroups,
    getHubContentDetailSite,
    getPortfolioHubDataSite,
    getStylesToRetrieve,
    parseQuerySite,
    stringifyQuery,
} from 'services'

// props
type PrivatePageSiteProps = {
    devId: string
    tabSlug: string
}

// main
export const PrivatePageSite: React.FC<PrivatePageSiteProps> = React.memo(({
    devId,
    tabSlug,
}) => {

    const dispatch = useDispatch()

    const history = useHistory()
    const location = useLocation()

    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource>()
    const [currencyId, setCurrencyId] = useState<number | undefined>(reduxModalSite.currencyId)
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [filterInfo, setFilterInfo] = useState<{
        filterSet: PortfolioHubContentFilterListSiteSerializer['filters'],
        tabSlug: string
    }>()
    const [hasSearchPage, setHasSearchPage] = useState(false)
    const [privateContentArray, setPrivateContentArray] = useState<PortfolioHubContentListSiteSerializer[]>()
    const [privateFilter, setPrivateFilter] = useState<any[]>()
    const [isLoading, setIsLoading] = useState(false)
    const [languageId, setLanguageId] = useState<number>(reduxModalSite.languageId)
    const [locationSearch, setLocationSearch] = useState<string | undefined>()
    const [menuOpen, setMenuOpen] = useState(false)
    const [pageContentHeader, setPageContentHeader] = useState<PortfolioPageContentListSiteSerializer[]>()
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[]>()
    const [pageObject, setPageObject] = useState<PageDetailSiteSerializer>()
    const [searchClearNumber, setSearchClearNumber] = useState<number | undefined>(reduxModalSite.searchClear)
    const [tabObject, setTabObject] = useState<PortfolioHubContentListSiteSerializer>()
    const [thumbSize, setThumbSize] = useState<string | undefined>()
    const [thumbSizeStyle, setThumbSizeStyle] = useState<string | undefined>()

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        const parsed = parseQuerySite(location.search)
        if (languageId !== reduxModalSite.languageId) {
            getPortfolioData(true)
            setLanguageId(reduxModalSite.languageId)
        } else if (currencyId !== reduxModalSite.currencyId) {
            getPortfolioData(true)
            setCurrencyId(reduxModalSite.currencyId)
        } else {
            if (locationSearch && locationSearch === getCleanLocationSearch()) {
                return
            } else {
                setLocationSearch(getCleanLocationSearch())
            }
            if ((reduxModalSite.searchClear !== searchClearNumber) || parsed.query || parsed.filter_query) {
                getPortfolioData()
                setSearchClearNumber(reduxModalSite.searchClear)
            } else {
                getPortfolioData()
            }
        }
    }, [
        location.search,
        reduxModalSite.currencyId,
        reduxModalSite.languageId,
        reduxModalSite.searchClear,
    ])

    useEffect(() => {  // bug tabSlug vs location.search if regrouped together with above useEffect, causing double fetch when going from a location search to a new tabSlug
        getPortfolioData()
        if (privateFilter) {
            setFilterInfo({
                filterSet: privateFilter.find((obj) => obj.slug === tabSlug)?.filters,
                tabSlug: tabSlug,
            })
        }
    }, [
        privateFilter,
        tabSlug,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (reduxModalSite.navbarType === 'top') {
            tempPageStyles.paddingTop = `${reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight}px`
        } else if (reduxModalSite.navbarType === 'side') {
            tempPageStyles.paddingLeft = `${reduxModalSite.navbarWidth}px`
        }
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'linkColor'))
    }, [
        reduxCacheSite.portfolio?.styles,
        reduxModalSite,
    ])

    useEffect(() => {
        if (deviceType !== 'is-web') {
            setMenuOpen(false)
        }
    }, [
        location,
    ])

    function getCleanLocationSearch() {
        try {
            const newLocationSearch = parseQuerySite(location.search)
            delete newLocationSearch.modal_page
            delete newLocationSearch.modal_detail_id
            return `${location.pathname}${JSON.stringify(newLocationSearch)}`
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'getCleanLocationSearch',
            ))
        }
    }

    function getCleanTab(newPageObject: PageDetailSiteSerializer, tab: string | undefined) {
        const availableTabs = []
        const private_content = newPageObject?.hub_content
        if (private_content?.component_list) availableTabs.push('list')
        if (private_content?.component_map) availableTabs.push('map')
        if (private_content?.component_square) availableTabs.push('square')
        if (tab && availableTabs.includes(tab)) return tab
        return private_content?.default_component!
    }

    function getPortfolioData(forceRefresh?: boolean) {
        try {
            getPortfolioHubDataSite({
                axiosCancelToken,
                component: 'PrivatePageSite',
                component_type: thumbSize,
                contentSlug: tabSlug,
                devId,
                dispatch,
                errorStatus,
                forceRefresh,
                location,
                needFilter: Boolean(!privateFilter),
                onFinished: (e: PageDetailSiteSerializer) => onPageLoadFinish(e),
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                setErrorStatus,
                setHasSearchPage,
                setHubFilter: setPrivateFilter,
                setHubObject: setPrivateContentArray,
                setIsLoading,
                setPageObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'getPortfolioData',
            ))
        }
    }

    function onPageLoadFinish(newPageObject: PageDetailSiteSerializer) {
        try {
            const newThumb = getCleanTab(newPageObject, parseQuerySite(location.search)?.tab)
            setThumbSize(newThumb)
            setThumbSizeStyle(newThumb)
            if (newThumb === 'map') {
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onPageLoadFinish')
            } else {
                getHubContentDetailSite({
                    axiosCancelToken,
                    component: 'PrivatePageSite',
                    componentType: newThumb,
                    contentSlug: tabSlug,
                    dispatch,
                    location,
                    portfolioId: newPageObject.portfolio!,
                    reduxAuth,
                    reduxModalSite,
                    setAxiosCancelToken,
                    setIsLoading,
                    setPageContentHeader,
                    setPageContentItems,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onPageLoadFinish',
            ))
        }
    }

    function onFilterChange(filterString: string) {
        try {
            const searchObject = parseQuerySite(location.search)
            searchObject.filter_query = filterString
            history.push(`${location.pathname}?${stringifyQuery(searchObject)}`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onFilterChange',
            ))
        }
    }

    function onSearch(searchValue: any, currentList?: boolean) {
        try {
            const searchObject = parseQuerySite(location.search)
            searchObject.query = searchValue
            if (currentList || !hasSearchPage || (thumbSize === 'map')) {
                history.push(`${location.pathname}?${stringifyQuery(searchObject)}`)
            } else {
                history.push(`${reduxModalSite.rootUrl || '/'}extra/private/search?${stringifyQuery(searchObject)}`)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onSearch',
            ))
        }
    }

    function onSearchClear() {
        try {
            const searchObject = parseQuerySite(location.search)
            delete searchObject.query
            history.push(`${location.pathname}?${stringifyQuery(searchObject)}`)
            dispatch(reduxModalClearSearchSite())
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onSearchClear',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onSearchKeyPress',
            ))
        }
    }

    function onSetTab(value: string) {
        try {
            setThumbSize(value)
            const searchObject = parseQuerySite(location.search)
            if (value === pageObject?.hub_content?.default_component) {
                delete searchObject.tab
            } else {
                searchObject.tab = value
            }
            history.push(`${location.pathname}?${stringifyQuery(searchObject)}`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onSetTab',
            ))
        }
    }

    if (errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent) {
        return (
            <NotFoundPortfolioSite statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent} />
        )
    }
    if (pageObject?.is_private && !reduxAuth?.authenticated) {
        return (
            <NotFoundPortfolioSite statusCode={403} />
        )
    }
    if (pageObject?.is_private && reduxAuth?.authenticated && pageObject.portfolio_user_groups?.length! > 0 && !checkPorfolioUserGroups({ portfolioId: reduxCacheSite.portfolio?.id!, pageUserGroups: pageObject.portfolio_user_groups!, reduxAuth })) {
        return (
            <NotFoundPortfolioSite statusCode={403} />
        )
    }
    return (
        <IonPage>
            <SeoBlockSite data={pageObject?.hub_content?.seo_data || pageObject?.seo_data} />
            <SeoBlockSite data={undefined} />
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxModalSite.isWindows} ${deviceType}`}>
                <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'PrivatePageSite' : ''} />
                {reduxCacheSite.portfolio && (
                    <div
                        className={`private-page-site ${[393, 439, 456].includes(reduxCacheSite.portfolio.id!) ? 'dark' : 'light'} lg-${reduxModalSite.languageId} ${deviceType}`}
                        style={newPageStyles}
                    >
                        <div
                            className={`hps-content ${thumbSizeStyle} ${deviceType}`}
                            style={{
                                fontFamily: fontFamily,
                                height: `calc(100vh - ${reduxModalSite.navbarHeight}px)`,
                            }}
                        >
                            <div
                                className={`hps-left mo-hidden-vertical-scrollbar${reduxModalSite.isWindows}${menuOpen ? ' open' : ''} ${deviceType}`}
                                style={{
                                    height: deviceType === 'is-mobile' ? `calc(100vh - ${reduxModalSite.navbarHeight}px - 50px)` : `calc(100vh - ${reduxModalSite.navbarHeight}px)`,
                                }}
                            >
                                <div className={`hps-header-left ${deviceType}`}>
                                    <div className={`hps-thumb-wrap${tabSlug ? '' : ' hidden'} ${deviceType}`}>
                                        {pageObject?.hub_content?.component_square && (
                                            <IconBlockSite
                                                className={`hps-size-icon${thumbSize === 'square' ? ' active' : ''} ${deviceType} p-${reduxCacheSite.portfolio.id}`}
                                                color='light'
                                                iconClass='mo-new-icon-gallery'
                                                onClick={() => onSetTab('square')}
                                            />
                                        )}
                                        {pageObject?.hub_content?.component_list && (
                                            <IconBlockSite
                                                className={`hps-size-icon${thumbSize === 'list' ? ' active' : ''} ${deviceType} p-${reduxCacheSite.portfolio.id}`}
                                                color='light'
                                                iconClass='mo-new-icon-list'
                                                onClick={() => onSetTab('list')}
                                            />
                                        )}
                                        {pageObject?.hub_content?.component_map && (
                                            <IconBlockSite
                                                className={`hps-size-icon${thumbSize === 'map' ? ' active' : ''} ${deviceType} p-${reduxCacheSite.portfolio.id}`}
                                                color='light'
                                                iconClass='mo-new-icon-map-marked-alt-solid'
                                                onClick={() => onSetTab('map')}
                                            />
                                        )}
                                    </div>
                                </div>
                                {privateContentArray?.map((val) => (
                                    <PrivateSideMenuTabWeb
                                        key={val.id}
                                        object={val}
                                        setTabObject={setTabObject}
                                        tabSlug={tabSlug}
                                    />
                                ))}
                            </div>
                            <div
                                className={`hps-right mo-hidden-vertical-scrollbar${reduxModalSite.isWindows} ${thumbSize} ${deviceType}`}
                                style={{
                                    height: `calc(100vh - ${reduxModalSite.navbarHeight}px)`,
                                }}
                            >
                                <div className={`hps-header ${thumbSize} ${deviceType}`}>
                                    <div className={`hps-search-wrap ${deviceType}`}>
                                        <div className={`hps-search-wrap-block ${deviceType}`}>
                                            {deviceType !== 'is-web' && (
                                                <IconBlockSite
                                                    className={`hps-icon ${deviceType}`}
                                                    color='light'
                                                    iconClass='mo-new-icon-list-ul-solid'
                                                    onClick={() => setMenuOpen(!menuOpen)}
                                                />
                                            )}
                                            <IonSearchbar
                                                className={`hps-searchbar ${thumbSize} ${deviceType}`}
                                                onKeyPress={(e) => onSearchKeyPress(e)}
                                                placeholder={reduxText[8254]}
                                                ref={searchBarRef}
                                                value={parseQuerySite(location.search)?.query}
                                                showClearButton={parseQuerySite(location.search)?.query ? 'always' : 'never'}
                                                onIonClear={onSearchClear}
                                            />
                                            <IconBlockSite
                                                className={`hps-icon ${deviceType}`}
                                                color='light'
                                                iconClass='mo-new-icon-search-solid'
                                                onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                                            />
                                        </div>
                                        {(hasSearchPage && (tabSlug !== 'search') && (thumbSize !== 'map')) && (
                                            <div
                                                className={`hps-search-in-list ${deviceType}`}
                                                onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value, true) }}
                                            >
                                                <p>{reduxText[8462]}{' '}{tabObject ? tabObject.name : reduxText[8463]}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {(!tabSlug || ['list', 'square'].includes(thumbSize!)) && (
                                    <React.Fragment>
                                        {filterInfo?.filterSet?.length! > 0 && (
                                            <div className={`hps-filter-wrap ${deviceType}`}>
                                                <PrivateFilterSite
                                                    filterInfo={filterInfo!}
                                                    languageId={reduxModalSite.languageId}
                                                    onChange={(e: string) => onFilterChange(e)}
                                                    portfolioId={reduxCacheSite.portfolio.id!}
                                                />
                                            </div>
                                        )}
                                        {!isLoading && pageContentHeader && pageContentHeader[0] && (
                                            <ContentBlockSite
                                                detailId={undefined}
                                                devId={devId}
                                                isEditHovered={false}
                                                linkColor={linkColor}
                                                object={pageContentHeader[0]}
                                                pageSlug='private'
                                                stylesEdit={undefined}
                                            />
                                        )}
                                        <div className={`hps-list${tabSlug ? ` ${thumbSizeStyle}` : ''} ${deviceType}`}>
                                            {!isLoading && pageContentItems?.filter(obj => obj.active === true).map((val) => (
                                                <ContentBlockSite
                                                    key={val.id}
                                                    detailId={undefined}
                                                    devId={devId}
                                                    hubComponentType={thumbSizeStyle}
                                                    hubContentSlug={tabSlug}
                                                    isEditHovered={false}
                                                    linkColor={linkColor}
                                                    object={val}
                                                    pageSlug='private'
                                                    stylesEdit={undefined}
                                                />
                                            ))}
                                            {!isLoading && (
                                                <p className={`hps-no-more ${deviceType}`}>{reduxText[8436]}</p>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                                {tabSlug && thumbSize === 'map' && (
                                    <PrivateMapSite
                                        devId={devId}
                                        filterInfo={filterInfo}
                                        tabSlug={tabSlug}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </IonContent>
        </IonPage>
    )
})
