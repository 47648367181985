// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
    InfiniteBlock,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template781SiteSerializer,
} from 'serializers/site'

// services
import {
    getModalAbsoluteUrlSite,
    getResponsiveImageSite,
    getStylesNew,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock781SiteProps = {
    blockId: string
    content: Template781SiteSerializer | Template781SiteSerializer[] | undefined
    contentArray?: Template781SiteSerializer[]
    detailId: string | undefined
    devId: string | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageNumberNew?: number
    pageSlug: string | undefined
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock781Site: React.FC<TemplateBlock781SiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    detailId,
    devId,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    pageNumberNew,
    pageSlug,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    if (Array.isArray(content)) {
        return (
            <React.Fragment>
                {content.map((item) => (
                    <TemplateBlock781HelperSite
                        key={item.id}
                        blockId={`${blockId}-${item.id}`}
                        content={item}
                        contentArray={contentArray || content}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        mainParentId={mainParentId}
                        object={object}
                        parentArray={parentArray}
                        parentStyles={parentStyles}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                ))}
                {object.infinite_list_page_size && content.length >= object.infinite_list_page_size && (
                    <InfiniteBlock
                        detailId={detailId}
                        devId={devId}
                        infiniteContentId={object.id!}
                        isButton={object.data_json?.infinite_list_button!}
                        isRandomOrdering={object.data_json?.is_random_ordering!}
                        linkColor={undefined}
                        pageNumberNew={(pageNumberNew || 1) + 1}
                        pageSlug={pageSlug}
                    />
                )}
            </React.Fragment>
        )
    }
    return (
        <TemplateBlock781HelperSite
            blockId={blockId}
            content={content}
            contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
            isEditHovered={isEditHovered}
            isInComponent={isInComponent}
            mainParentId={mainParentId}
            object={object}
            parentArray={parentArray}
            parentStyles={parentStyles}
            styles={styles}
            stylesEdit={stylesEdit}
        />
    )
})

// helpers

// props
type TemplateBlock781HelperSiteProps = {
    blockId: string
    content: Template781SiteSerializer | undefined
    contentArray: Template781SiteSerializer[] | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
const TemplateBlock781HelperSite: React.FC<TemplateBlock781HelperSiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxFormSiteportfolioObjecthome_page_slug = useSelector((state: defaultReduxState) => state.reduxFormSite.portfolioObject?.home_page_slug)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [href, setHref] = useState(false)
    const [noTargetBlank, setNoTargetBlank] = useState(false)
    const [positions, setPositions] = useState<any>('leave')
    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())
    const [toUrl, setToUrl] = useState<string>()

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        const action = object.action
        const to = content?.absolute_site_url
        let toUrl: string | undefined = undefined
        let isHref = false
        const isNotTargetBlank = false
        if (['edit', 'navMixed', 'preview'].includes(reduxFormSitenavigationMode!)) {
            toUrl = undefined
        } else if (action && to?.includes('url-detail/') && content?.link_url) {
            toUrl = content.link_url
            isHref = true
        } else if (action && reduxModalSite.deviceType === 'is-web' && ['modal_link'].includes(action) && typeof to !== 'undefined' && to !== null) {
            toUrl = (reduxFormSitenavigationMode === 'navPage') ? `${reduxModalSite.rootUrl || '/'}${to}` : getModalAbsoluteUrlSite(to)
        } else if (action && ['href', 'purchase_link'].includes(action) && to) {
            // href = { to } TODO
            toUrl = to
            isHref = true
        } else if (action && (reduxModalSite.deviceType !== 'is-web') && to?.includes('pdf-detail/') && content?.link_url) {
            toUrl = content.link_url
            isHref = true
            // isNotTargetBlank = true
        } else if (action && ['generic_link', 'modal_link', 'page_link', 'filter_param'].includes(action) && (typeof to !== 'undefined' && to !== null)) {
            toUrl = `${reduxModalSite.rootUrl || '/'}${(to === '' && (reduxFormSitenavigationMode === 'navPage')) ? reduxFormSiteportfolioObjecthome_page_slug : to}`
        } else if (action && ['play'].includes(action) && (typeof to !== 'undefined' && to !== null)) {
            toUrl = `${reduxModalSite.rootUrl || '/'}${to}`
        }
        // if (action === 'download' && dowloadFile) {
        // }
        setToUrl(toUrl)
        setHref(isHref)
        setNoTargetBlank(isNotTargetBlank)
    }, [
        content?.absolute_site_url,
        content?.link_url,
        object.action,
        reduxFormSitenavigationMode,
        reduxModalSite.deviceType,
        reduxModalSite.rootUrl,
    ])

    function applyStyles() {
        try {
            const newStyles = getStylesNew(reduxModalSite, styles, stylesEdit) || {}
            const lmaxWidth = newStyles.lmaxWidth
            const pmaxWidth = newStyles.pmaxWidth
            if (lmaxWidth && content?.image_orientation === 'l') {
                newStyles.maxWidth = lmaxWidth
            }
            if (pmaxWidth && content?.image_orientation === 'p') {
                newStyles.maxWidth = pmaxWidth
            }
            if (object.action) {
                newStyles.cursor = 'pointer'
            }
            return newStyles
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock781HelperSite',
                'applyStyles',
            ))
        }
    }

    function getPosition(e: any) {
        try {
            setPositions(e)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock781HelperSite',
                'getPosition',
            ))
        }
    }

    // @ts-ignore
    const imageSrc = content ? getResponsiveImageSite(reduxModalSite, content, object.data_json?.hq_quality, object.data_json?.lq_quality, object.data_json?.mq_quality) : undefined

    if (object.data_json?.hidden_if_no_image && !imageSrc) return null

    return (
        <React.Fragment>
            <ImageHelperSite
                id={blockId}
                alt={content?.alt ? `${content?.alt} | ${reduxCacheSite.portfolio?.name}` : reduxCacheSite.portfolio?.name}
                className={`template-block-781${toUrl ? ' pointer' : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                dominant_color={content?.get_dominant_color}
                onClick={() => (content && contentArray) ? onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content, object, contentArray, undefined, history, toUrl, href, noTargetBlank) : undefined}
                onEditHover={(e) => getPosition(e)}
                src={imageSrc}
                styles={stylesNew}
            />
            <EditBlockSite
                customPosition={positions}
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                resetCustomPosition={() => setPositions('leave')}
            />
        </React.Fragment>
    )
})
