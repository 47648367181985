// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useLocation,
} from 'react-router-dom'
import slugify from 'slugify'

// data
import {
    defaultReduxState,
    reduxAuthLogoutSite,
    reduxModalErrorEventHandlerSite,
    reduxModalSetActiveDropdownIdSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
} from 'serializers/site'

// services
import {
    getModalAbsoluteUrlSite,
} from 'services'

// props
type LinkHelperSiteProps = {
    action: string | undefined
    children?: React.ReactNode
    className?: string
    contentSource?: number
    disabled?: boolean
    dowloadFile?: string
    forceTo?: string | undefined
    id?: string
    imageArray?: any
    index?: any
    isActive?: boolean
    lightBox?: any
    linkUrl?: string
    onClick: (e?: any) => void
    onCustomMouseLeave?: () => void
    onCustomMouseOver?: () => void
    parentDropdownId?: number
    style?: CustomCSSProperties | undefined
    styleActive?: CustomCSSProperties | undefined
    textTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
    title?: string
    to: string | undefined
}

// main
export const LinkHelperSite: React.FC<LinkHelperSiteProps> = React.memo(({
    action,
    children,
    className,
    contentSource,
    disabled,
    dowloadFile,
    forceTo,
    id,
    isActive,
    linkUrl,
    onClick,
    onCustomMouseLeave,
    onCustomMouseOver,
    parentDropdownId,
    style,
    styleActive,
    textTag,
    title,
    to,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuthauthenticated = useSelector((state: defaultReduxState) => state.reduxAuth?.authenticated)
    // const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxFormSiteportfolioObjecthome_page_slug = useSelector((state: defaultReduxState) => state.reduxFormSite.portfolioObject?.home_page_slug)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    useEffect(() => {
        if (style) {
            const newnewStyle = Object.assign({}, style)
            if (action) {
                if (['href', 'purchase_link'].includes(action) && !to) {
                    newnewStyle.cursor = undefined
                } else if (['modal_link', 'generic_link', 'page_link', 'page_link_content_object', 'filter_param'].includes(action) && ((typeof to === 'undefined') || to === null)) {
                    newnewStyle.cursor = undefined
                } else {
                    newnewStyle.cursor = 'pointer'
                }
                if (styleActive && (isActive || (typeof to !== 'undefined' && to !== null && slugify(`${location.pathname}${contentSource === 35 ? '' : location.search}`, { remove: /[*+~.()'"!:@/]/g, lower: true }) === slugify(`${reduxModalSite.rootUrl}${to}`, { remove: /[*+~.()'"!:@/]/g, lower: true })))) {
                    if (styleActive.background) newnewStyle.background = styleActive.background
                    if (styleActive.borderColor) newnewStyle.borderColor = styleActive.borderColor
                    if (styleActive.borderStyle) newnewStyle.borderStyle = styleActive.borderStyle
                    if (styleActive.borderWidth) newnewStyle.borderWidth = styleActive.borderWidth
                    if (styleActive.color) newnewStyle.color = styleActive.color
                    if (styleActive.fontWeight) newnewStyle.fontWeight = styleActive.fontWeight
                    if (styleActive.opacity) newnewStyle.opacity = styleActive.opacity
                    if (styleActive.textDecorationColor) newnewStyle.textDecorationColor = styleActive.textDecorationColor
                    if (styleActive.textDecorationLine) newnewStyle.textDecorationLine = styleActive.textDecorationLine
                    if (styleActive.textDecorationStyle) newnewStyle.textDecorationStyle = styleActive.textDecorationStyle
                    if (styleActive.textDecorationThickness) newnewStyle.textDecorationThickness = styleActive.textDecorationThickness
                    if (styleActive.textUnderlineOffset) newnewStyle.textUnderlineOffset = styleActive.textUnderlineOffset
                    if (parentDropdownId && !reduxModalSite.activeDropdownId && reduxModalSite.activeDropdownId !== parentDropdownId) {
                        dispatch(reduxModalSetActiveDropdownIdSite(parentDropdownId))
                    }
                }
            }
            if (disabled) {
                newnewStyle.pointerEvents = 'none'
                newnewStyle.opacity = 0.5
            }
            setNewStyles(newnewStyle)
        } else if (action) {
            setNewStyles({
                cursor: 'pointer',
            })
        } else {
            setNewStyles(undefined)
        }
    }, [
        disabled,
        isActive,
        location.pathname,
        style,
    ])

    const [newStyles, setNewStyles] = useState<any>(undefined)

    function onLinkClick(e: any) {
        try {
            if (onClick) {
                onClick(e)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'LinkHelperSite',
                'onLinkClick',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (isActive) return
            if (styleActive) {
                if (direction === 'over') {
                    if (style) {
                        const newnewStyle = Object.assign({}, style)
                        if (action) {
                            if (['href', 'purchase_link'].includes(action) && !to) {
                                newnewStyle.cursor = undefined
                            } else if (['modal_link', 'generic_link', 'page_link', 'page_link_content_object', 'filter_param'].includes(action) && ((typeof to === 'undefined') || to === null)) {
                                newnewStyle.cursor = undefined
                            } else {
                                newnewStyle.cursor = 'pointer'
                            }
                            if (styleActive.background) newnewStyle.background = styleActive.background
                            if (styleActive.borderColor) newnewStyle.borderColor = styleActive.borderColor
                            if (styleActive.borderStyle) newnewStyle.borderStyle = styleActive.borderStyle
                            if (styleActive.borderWidth) newnewStyle.borderWidth = styleActive.borderWidth
                            if (styleActive.color) newnewStyle.color = styleActive.color
                            if (styleActive.fontWeight) newnewStyle.fontWeight = styleActive.fontWeight
                            if (styleActive.opacity) newnewStyle.opacity = styleActive.opacity
                            if (styleActive.textDecorationColor) newnewStyle.textDecorationColor = styleActive.textDecorationColor
                            if (styleActive.textDecorationLine) newnewStyle.textDecorationLine = styleActive.textDecorationLine
                            if (styleActive.textDecorationStyle) newnewStyle.textDecorationStyle = styleActive.textDecorationStyle
                            if (styleActive.textDecorationThickness) newnewStyle.textDecorationThickness = styleActive.textDecorationThickness
                            if (styleActive.textUnderlineOffset) newnewStyle.textUnderlineOffset = styleActive.textUnderlineOffset
                        }
                        setNewStyles(newnewStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                } else {
                    if (style) {
                        const newnewStyle = Object.assign({}, style)
                        if (action) {
                            if (['href', 'purchase_link'].includes(action) && !to) {
                                newnewStyle.cursor = undefined
                            } else if (['modal_link', 'generic_link', 'page_link', 'page_link_content_object', 'filter_param'].includes(action) && ((typeof to === 'undefined') || to === null)) {
                                newnewStyle.cursor = undefined
                            } else {
                                newnewStyle.cursor = 'pointer'
                            }
                            if (styleActive && (isActive || (typeof to !== 'undefined' && to !== null && slugify(`${location.pathname}${contentSource === 35 ? '' : location.search}`, { remove: /[*+~.()'"!:@/]/g, lower: true }) === slugify(`${reduxModalSite.rootUrl}${to}`, { remove: /[*+~.()'"!:@/]/g, lower: true })))) {
                                if (styleActive.background) newnewStyle.background = styleActive.background
                                if (styleActive.borderColor) newnewStyle.borderColor = styleActive.borderColor
                                if (styleActive.borderStyle) newnewStyle.borderStyle = styleActive.borderStyle
                                if (styleActive.borderWidth) newnewStyle.borderWidth = styleActive.borderWidth
                                if (styleActive.color) newnewStyle.color = styleActive.color
                                if (styleActive.fontWeight) newnewStyle.fontWeight = styleActive.fontWeight
                                if (styleActive.opacity) newnewStyle.opacity = styleActive.opacity
                                if (styleActive.textDecorationColor) newnewStyle.textDecorationColor = styleActive.textDecorationColor
                                if (styleActive.textDecorationLine) newnewStyle.textDecorationLine = styleActive.textDecorationLine
                                if (styleActive.textDecorationStyle) newnewStyle.textDecorationStyle = styleActive.textDecorationStyle
                                if (styleActive.textDecorationThickness) newnewStyle.textDecorationThickness = styleActive.textDecorationThickness
                                if (styleActive.textUnderlineOffset) newnewStyle.textUnderlineOffset = styleActive.textUnderlineOffset
                            }
                        }
                        setNewStyles(newnewStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'LinkHelperSite',
                'onHover',
            ))
        }
    }

    if (['edit', 'navMixed', 'preview'].includes(reduxFormSitenavigationMode!)) {
        if (textTag) {
            if (textTag === 'span') {
                return (
                    <span
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </span>
                )
            }
            if (textTag === 'h1') {
                return (
                    <h1
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </h1>
                )
            }
            if (textTag === 'h2') {
                return (
                    <h2
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </h2>
                )
            }
            if (textTag === 'h3') {
                return (
                    <h3
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </h3>
                )
            }
            if (textTag === 'h4') {
                return (
                    <h4
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </h4>
                )
            }
            if (textTag === 'h5') {
                return (
                    <h5
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </h5>
                )
            }
            if (textTag === 'h6') {
                return (
                    <h6
                        id={id}
                        className={className}
                        onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                        onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                        onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                        style={newStyles}
                        title={title}
                    >
                        {children}
                    </h6>
                )
            }
            return (
                <p
                    id={id}
                    className={className}
                    onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </p>
            )
        }
        return (
            <div
                id={id}
                className={className}
                onClick={(e) => reduxFormSitenavigationMode === 'navMixed' ? onLinkClick(e) : undefined}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                style={newStyles}
                title={title}
            >
                {children}
            </div>
        )
    }
    if (action && to?.includes('url-detail/') && linkUrl) {
        return (
            <a
                id={id}
                className={className}
                href={linkUrl}
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                rel='noopener noreferrer'
                style={newStyles}
                target='_blank'
                title={title}
            >
                {children}
            </a>
        )
    }
    if (action && reduxModalSite.deviceType === 'is-web' && ['modal_link'].includes(action) && typeof to !== 'undefined' && to !== null) {
        return (
            <NavLink
                id={id}
                activeClassName='active'
                className={className}
                exact
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                style={newStyles}
                title={title}
                to={(reduxFormSitenavigationMode === 'navPage') ? (forceTo || `${reduxModalSite.rootUrl || '/'}${(to === '') ? reduxFormSiteportfolioObjecthome_page_slug : to}`) : getModalAbsoluteUrlSite(to)}
            >
                {children}
            </NavLink>
        )
    }
    if (action && ['href', 'purchase_link'].includes(action) && to) {
        return (
            <a
                id={id}
                className={className}
                href={to}
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                rel='noopener noreferrer'
                style={newStyles}
                target='_blank'
                title={title}
            >
                {children}
            </a>
        )
    }
    if (action && (reduxModalSite.deviceType !== 'is-web') && to?.includes('pdf-detail/') && linkUrl) {
        return (
            <a
                id={id}
                className={className}
                href={linkUrl}
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                rel='noopener noreferrer'
                style={newStyles}
                target='_blank'
                title={title}
            >
                {children}
            </a>
        )
    }
    if (action && ['generic_link', 'modal_link', 'page_link', 'page_link_content_object', 'filter_param', 'search'].includes(action) && (forceTo || (typeof to !== 'undefined' && to !== null))) {
        return (
            <NavLink
                id={id}
                activeClassName='active'
                className={className}
                exact
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                style={newStyles}
                title={title}
                to={forceTo || `${reduxModalSite.rootUrl || '/'}${(to === '' && (reduxFormSitenavigationMode === 'navPage')) ? reduxFormSiteportfolioObjecthome_page_slug : to}`}
            >
                {children}
            </NavLink>
        )
    }
    if (action === 'download' && dowloadFile) {
        return (
            <a
                id={id}
                className={className}
                href={dowloadFile}
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                rel='noopener noreferrer'
                style={newStyles}
                target='_blank'
                title={title}
            >
                {children}
            </a>
        )
    }
    if (action === 'logout') {
        if (!reduxAuthauthenticated) return null
        return (
            <span
                id={id}
                className={className}
                onClick={(e) => dispatch(reduxAuthLogoutSite())}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                style={newStyles}
                title={title}
            >
                {children}
            </span>
        )
    }
    if (textTag) {
        if (textTag === 'span') {
            return (
                <span
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </span>
            )
        }
        if (textTag === 'h1') {
            return (
                <h1
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </h1>
            )
        }
        if (textTag === 'h2') {
            return (
                <h2
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </h2>
            )
        }
        if (textTag === 'h3') {
            return (
                <h3
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </h3>
            )
        }
        if (textTag === 'h4') {
            return (
                <h4
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </h4>
            )
        }
        if (textTag === 'h5') {
            return (
                <h5
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </h5>
            )
        }
        if (textTag === 'h6') {
            return (
                <h6
                    id={id}
                    className={className}
                    onClick={(e) => onLinkClick(e)}
                    onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                    onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                    style={newStyles}
                    title={title}
                >
                    {children}
                </h6>
            )
        }
        return (
            <p
                id={id}
                className={className}
                onClick={(e) => onLinkClick(e)}
                onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
                onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
                style={newStyles}
                title={title}
            >
                {children}
            </p>
        )
    }
    return (
        <div
            id={id}
            className={className}
            onClick={(e) => onLinkClick(e)}
            onMouseLeave={() => onCustomMouseLeave ? onCustomMouseLeave() : onHover('leave')}
            onMouseOver={() => onCustomMouseOver ? onCustomMouseOver() : onHover('over')}
            style={newStyles}
            title={title}
        >
            {children}
        </div>
    )
})
