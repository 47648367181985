// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    PayPalButtons,
    usePayPalScriptReducer
} from '@paypal/react-paypal-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    api_url_portfolio_sales_paypal_create,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesCartToggleSite,
    reduxSalesSetInfoSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// props
type StripeCheckoutSiteProps = {
    billing_info: any
    contact_info: any
    setErrors: React.Dispatch<any>
    setHasError: React.Dispatch<boolean>
    setMainErrors: React.Dispatch<any>
    shipping_info: any
}

// main
export const PaypalCheckoutSite: React.FC<StripeCheckoutSiteProps> = ({
    billing_info,
    contact_info,
    setErrors,
    setHasError,
    setMainErrors,
    shipping_info,
}) => {

    const [{ options, isRejected }, dispatchPaypal] = usePayPalScriptReducer()

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [isOkToPay, setIsOkToPay] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const amount = Number(reduxSalesSite.subTotal) + Number(reduxSalesSite.shippingTotal || 0)
    const currency = reduxSalesSite.currency
    const style = { layout: 'vertical', shape: 'pill' }

    useEffect(() => {
        dispatchPaypal({
            type: 'resetOptions',
            value: {
                ...options,
                currency: currency,
            },
        })
    }, [
        currency,
    ])

    function handleCheckData() {
        try {
            let okToPay = true
            const errors: any = {
                billing_info: {},
                contact_info: {},
                shipping_info: {},
            }
            Object.keys(contact_info).map((val) => {
                if (val !== 'phone' && !contact_info[val]) {
                    okToPay = false
                    errors.contact_info[val] = reduxText[7489]
                } else if (val === 'email') {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!re.test(contact_info[val])) {
                        okToPay = false
                        errors.contact_info[val] = reduxText[7490]
                    }
                }
                setIsOkToPay(false)
            })
            Object.keys(shipping_info).map((val) => {
                const optionalFields = ['number']
                if (!optionalFields.includes(val) && !shipping_info[val]) {
                    okToPay = false
                    errors.shipping_info[val] = reduxText[7489]
                }
                setIsOkToPay(false)
            })
            if (!billing_info.same_as_shipping) {
                const optionalFields = ['number']
                Object.keys(billing_info).map((val) => {
                    if (val !== 'same_as_shipping' && !optionalFields.includes(val) && !billing_info[val]) {
                        okToPay = false
                        errors.billing_info[val] = reduxText[7489]
                    }
                    setIsOkToPay(false)
                })
            }
            setErrors(errors)
            setHasError(!okToPay)
            return setIsOkToPay(okToPay)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'StripeCheckoutSite',
                'handleCheckData',
            ))
        }
    }

    function createOrder(orderId: string) {
        try {
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) createOrder')
            dispatch(reduxSalesSetInfoSite(
                billing_info,
                contact_info,
                shipping_info,
            ))
            const sales_order_lines: any = []
            reduxSalesSite.salesLines.map((val) => {
                const packageOptionArray: number[] = []
                val.package_option_items?.map((val2) => {
                    packageOptionArray.push(val2.id!)
                })
                sales_order_lines.push({
                    package_option_items: packageOptionArray,
                    product: val.product.id,
                    product_variant: val.variant?.id,
                    quantity: val.qty,
                    unit_price: val.product.unit_price,
                })
                return false
            })
            const same_as_shipping = billing_info.same_as_shipping
            const formData = {
                billing_address: same_as_shipping ? shipping_info.address : billing_info.address,
                billing_city: same_as_shipping ? shipping_info.city : billing_info.city,
                billing_country: same_as_shipping ? shipping_info.country.id : billing_info.country.id,
                billing_first_name: same_as_shipping ? contact_info.first_name : billing_info.first_name,
                billing_last_name: same_as_shipping ? contact_info.last_name : billing_info.last_name,
                billing_zip_code: same_as_shipping ? shipping_info.zip_code : billing_info.zip_code,
                customer_email: contact_info.email,
                customer_first_name: contact_info.first_name,
                customer_last_name: contact_info.last_name,
                customer_phone: contact_info.phone,
                portfolio: reduxCacheSite.portfolio?.id,
                sales_order_lines: sales_order_lines,
                shipping_address: shipping_info.address,
                shipping_city: shipping_info.city,
                shipping_country: shipping_info.country.id,
                shipping_zip_code: shipping_info.zip_code,
                stripe_session_id: orderId,
            }
            const postUrl = getApiUrlSite(api_url_portfolio_sales_paypal_create, reduxModalSite)
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) createOrder')
                    dispatch(reduxSalesCartToggleSite(false))
                    history.push(`${reduxModalSite.rootUrl || '/'}checkout-success#transactionId=${response.data.unique_transaction_id}`)
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) createOrder')
                    setMainErrors(error?.response?.data)
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'PaypalCheckoutSite',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formData),
                        reduxAuth,
                        reduxModalSite,
                        reference: 'createOrder',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PaypalCheckoutSite',
                'createOrder',
            ))
        }
    }

    if (isRejected) {
        return (
            <div className={`portfolio-checkout-form ${deviceType}`}>
                <div className={`pc-validation ${deviceType}`}>
                    <button
                        className={`pc-validation-button error ${deviceType}`}
                        disabled
                    >
                        {reduxText[8603]}
                    </button>
                </div>
                <div className={`cf-text-stripe ${deviceType}`}>
                    <span>{reduxText[7492]}{' '}</span><a href='https://www.paypal.com/' target='_blank' rel='noopener noreferrer'>Paypal</a>
                </div>
                <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'PaypalCheckoutSite isRejected' : ''} />
            </div>
        )
    }

    if (!isOkToPay) {
        return (
            <div className={`portfolio-checkout-form ${deviceType}`}>
                <div className={`pc-validation ${deviceType}`}>
                    <button
                        className={`pc-validation-button ${deviceType}`}
                        onClick={handleCheckData}
                    >
                        {reduxText[7491]}{' '}{Number((Number(reduxSalesSite.subTotal) + Number(reduxSalesSite.shippingTotal || 0)).toFixed(2))} {reduxSalesSite.currency_symbol}
                    </button>
                </div>
                <div className={`cf-text-stripe ${deviceType}`}>
                    <span>{reduxText[7492]}{' '}</span><a href='https://www.paypal.com/' target='_blank' rel='noopener noreferrer'>Paypal</a>
                </div>
                <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'PaypalCheckoutSite !isOkToPay' : ''} />
            </div>
        )
    }

    return (
        <>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount.toString(),
                                    },
                                    // @ts-ignore
                                    shipping: {
                                        name: {
                                            full_name: `${contact_info.first_name} ${contact_info.last_name}`,
                                        },
                                        email_address: contact_info.email,
                                        address: {
                                            address_line_1: shipping_info.address,
                                            admin_area_2: shipping_info.city,
                                            postal_code: shipping_info.zip_code,
                                            country_code: shipping_info.country.country,
                                        }
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            return orderId
                        })
                }}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                onApprove={function (data, actions) {
                    return actions.order!.capture().then(function () {
                        createOrder(data.orderID)
                    })
                }}
                //@ts-ignore
                style={style}
            />
            <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'PaypalCheckoutSite' : ''} />
        </>
    )
}
