// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    Location,
} from 'history'
import slugify from 'slugify'

// data
import {
    api_url_portfolio_detail_id_site,
    api_url_portfolio_detail_site,
    reduxAuthStateSite,
    reduxCacheSetPortfolioSite,
    reduxCacheStateSite,
    reduxModaSetDeviceTypeSite,
    reduxModalSetCurrencyIdSite,
    reduxModalSetLanguageIdSite,
    reduxModalSlaaskInitSite,
    reduxModalStateSite,
    reduxSalesResetSite,
} from 'data'

// serializers
import {
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    getHostOriginSite,
    getPageContentDataSite,
    getPageDataSite,
    parseQuerySite,
} from 'services'

// main
export function getPortfolioDataSite({
    axiosCancelToken,
    backgroundLoad,
    component,
    detailId,
    devId,
    dispatch,
    errorStatus,
    location,
    noEmpty,
    pageSlug,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    scrollToTop,
    setErrorStatus,
    setIsLoading,
    setPageContentItems,
    setPageObject,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    backgroundLoad: boolean
    component: string
    detailId: string | undefined
    devId: string | undefined
    dispatch: React.Dispatch<any>
    errorStatus: errorStatusTypeSite
    location: Location
    noEmpty?: boolean
    pageSlug: string | undefined
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    scrollToTop: any | undefined
    setErrorStatus: React.Dispatch<errorStatusTypeSite>
    setIsLoading: React.Dispatch<boolean>
    setPageContentItems: React.Dispatch<any>
    setPageObject: React.Dispatch<any>
}) {

    setErrorStatus({})

    if (!noEmpty) {
        setIsLoading(true)
        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getPortfolioDataSite')
    }

    const hostOriginSite = getHostOriginSite()
    const portfolioId = devId?.split('-')[0]
    const profileId = devId?.split('-')[1]
    const deviceType = devId?.split('-')[2] || 'web'

    let portfolioApiUrl = ''
    if (hostOriginSite === 'demo') {
        portfolioApiUrl = getApiUrlSite(`${api_url_portfolio_detail_id_site}${portfolioId}/?profile_id=${profileId}`, reduxModalSite)
        dispatch(reduxModaSetDeviceTypeSite(`is-${deviceType}`))
    } else if (hostOriginSite === 'site') {
        portfolioApiUrl = getApiUrlSite(`${api_url_portfolio_detail_id_site}${portfolioId}/`, reduxModalSite)
    } else {
        portfolioApiUrl = getApiUrlSite(`${api_url_portfolio_detail_site}${slugify(window.location.hostname, { remove: /[*+~.()'"!:@]/g, lower: true })}/`, reduxModalSite)
    }
    if ((portfolioApiUrl).includes('?')) {
        portfolioApiUrl += `&language_id=${reduxModalSite.languageId}`
    } else {
        portfolioApiUrl += `?language_id=${reduxModalSite.languageId}`
    }
    if (reduxModalSite.currencyId) portfolioApiUrl += `&currency_id=${reduxModalSite.currencyId}`

    const searchParams = parseQuerySite(location.search)
    const deviceTypeSite = searchParams.deviceType

    if (deviceTypeSite) {
        dispatch(reduxModaSetDeviceTypeSite(deviceTypeSite))
    }

    if (reduxCacheSite.portfolios?.[portfolioApiUrl]) {
        if (reduxCacheSite.portfolioCurrentApiUrl !== portfolioApiUrl) {
            dispatch(reduxCacheSetPortfolioSite(portfolioApiUrl, reduxCacheSite.portfolios[portfolioApiUrl]))
        }
        return
    }

    if (process.env.NODE_ENV === 'development') console.log(portfolioApiUrl)

    axios({
        cancelToken: axiosCancelToken?.token,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: 'get',
        url: portfolioApiUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            dispatch(reduxCacheSetPortfolioSite(portfolioApiUrl, response.data))

            if (response.data.coming_soon && hostOriginSite === 'prod') {
                return
            }

            const languageCodeArray: number[] = []
            response.data.languages?.map((val: any) => {
                languageCodeArray.push(val.id)
            })
            if (languageCodeArray.length > 0 && !languageCodeArray.includes(reduxModalSite.languageId!)) {
                dispatch(reduxModalSetLanguageIdSite(response.data.languages[0].id))
                return
            }

            if (response.data.is_multi_currency) {
                const currencyCodeArray: number[] = []
                response.data.currencies?.map((val: any) => {
                    currencyCodeArray.push(val.id)
                })
                if (currencyCodeArray.length > 0 && !currencyCodeArray.includes(reduxModalSite.currencyId!)) {
                    dispatch(reduxModalSetCurrencyIdSite(response.data.currency.id))
                    dispatch(reduxSalesResetSite())
                    return
                }
            } else if (reduxModalSite.currencyId) {
                dispatch(reduxModalSetCurrencyIdSite(undefined))
                dispatch(reduxSalesResetSite())
                return
            }

            const newPageSlug = pageSlug || response.data.home_page_slug

            if (!backgroundLoad && response.data.id && newPageSlug) {

                if (response.data.url_redirect?.find((obj: any) => obj.old_url === newPageSlug)) {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPortfolioDataSite')
                    return
                }

                getPageDataSite({
                    axiosCancelToken,
                    component,
                    detailId,
                    dispatch,
                    errorStatus,
                    pageSlug: newPageSlug,
                    portfolioObject: response.data,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    scrollToTop,
                    setErrorStatus,
                    setIsLoading,
                    setPageObject,
                })

                getPageContentDataSite({
                    axiosCancelToken,
                    component,
                    detailId,
                    devId,
                    dispatch,
                    errorStatus,
                    location,
                    noEmpty,
                    pageSlug: newPageSlug,
                    portfolioObject: response.data,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    setErrorStatus,
                    setIsLoading,
                    setPageContentItems,
                })
            } else {
                setIsLoading(false)
                if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPortfolioDataSite')
            }

            if (response.data.slaask_api_key && !reduxModalSite.slaaskApiKey) {
                dispatch(reduxModalSlaaskInitSite(response.data.slaask_api_key))
            }
        })
        .catch((error) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPortfolioDataSite')
            if ([403, 404].includes(error.response?.status)) {
                setErrorStatus({
                    ...errorStatus,
                    portfolio: error.response.status,
                })
            } else {
                axiosErrorHandlerSite({
                    apiUrl: portfolioApiUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reduxModalSite,
                    reference: 'getPortfolioDataSite',
                })
            }
        })
}
