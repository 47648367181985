// data
import {
    REDUX_FORM_AUTO_SAVE_ADD_SITE,
    REDUX_FORM_AUTO_SAVE_REMOVE_SITE,
    REDUX_FORM_SET_AVAILABILITY_SITE,
    REDUX_FORM_SET_COLLAPSE_STYLES_SITE,
    REDUX_FORM_SET_DATA_SITE,
    REDUX_FORM_SET_DYNAMIC_TEXT_OPTIONS_SITE,
    REDUX_FORM_SET_HOVERED_BLOCK_ID_SITE,
    REDUX_FORM_SET_IS_EDITING_SITE,
    REDUX_FORM_SET_NAVIGATION_MODE_SITE,
    REDUX_FORM_SET_PAGE_LIST_SITE,
    REDUX_FORM_SET_PARENT_ACTIVE_SITE,
    REDUX_FORM_SET_SECTION_COLLAPSE_SITE,
    REDUX_FORM_SET_SECTION_ID_SITE,
    REDUX_FORM_SHOW_EDIT_ALERT_SITE,
} from './mo_actions'
import {
    reduxFormStateSite,
} from './mo_state'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/site'

// main
const initialState: reduxFormStateSite = {
    availableContentSources: [],
    availableDetailPageMediumTypes: [],
    availableMediumTypes: [],
    collapseStyles: true,
    dynamicTextOptions: undefined,
    editAutoSave: {},
    editAutoSaved: {},
    editFunction: undefined,
    editUrl: undefined,
    editingForm: false,
    hoveredBlockId: undefined,
    navigationMode: undefined,
    pageContentItems: undefined,
    pageObject: undefined,
    parentContent: undefined,
    portfolioObject: undefined,
    sectionCollapse: false,
    sectionId: undefined,
    showEditAlert: false,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_FORM_AUTO_SAVE_ADD_SITE: {
            return {
                ...state,
                editAutoSave: {
                    ...state.editAutoSave,
                    [action.payload.contentId]: {
                        styles: action.payload.styles,
                        styles_original: action.payload.styles_original,
                        udpdateUrl: action.payload.udpdateUrl,
                    },
                }
            }
        }
        case REDUX_FORM_AUTO_SAVE_REMOVE_SITE: {
            const newEditAutoSave = Object.assign({}, state.editAutoSave)
            delete newEditAutoSave[action.payload.contentId]
            if (action.payload.cancel) {
                return {
                    ...state,
                    editAutoSave: newEditAutoSave,
                }
            }
            return {
                ...state,
                editAutoSave: newEditAutoSave,
                editAutoSaved: {
                    ...state.editAutoSaved,
                    [action.payload.contentId]: state.editAutoSave[action.payload.contentId]
                },
            }
        }
        case REDUX_FORM_SET_AVAILABILITY_SITE: {
            const detailPageMediumTypArray: number[] = []
            const paylaodMediumTypeArray = [...action.payload.medium_types, ...action.payload.detail_medium_types]
            paylaodMediumTypeArray.map((val) => {
                if (val.id) detailPageMediumTypArray.push(val.id)
                return false
            })
            return {
                ...state,
                availableContentSources: action.payload.content_sources,
                availableDetailPageMediumTypes: detailPageMediumTypArray,
                availableMediumTypes: action.payload.medium_types,
            }
        }
        case REDUX_FORM_SET_COLLAPSE_STYLES_SITE: {
            return {
                ...state,
                collapseStyles: action.payload,
            }
        }
        case REDUX_FORM_SET_DATA_SITE: {
            return {
                ...state,
                [action.payload.branch]: action.payload.content,
            }
        }
        case REDUX_FORM_SET_DYNAMIC_TEXT_OPTIONS_SITE: {
            const dynamicTextOptionsObj: {
                [key: number]: MainIdNameSerializer[]
            } = {}
            action.payload.map((val: any) => {
                dynamicTextOptionsObj[val.id] = val.options
            })
            return {
                ...state,
                dynamicTextOptions: dynamicTextOptionsObj,
            }
        }
        case REDUX_FORM_SET_HOVERED_BLOCK_ID_SITE: {
            return {
                ...state,
                hoveredBlockId: action.payload,
            }
        }
        case REDUX_FORM_SET_IS_EDITING_SITE: {
            if (action.payload.styles_original) {
                return {
                    ...state,
                    navigationMode: 'edit',
                }
            }
            if (action.payload.boolean) {
                return {
                    ...state,
                    editingForm: action.payload,
                    navigationMode: 'edit',
                }
            }
            return {
                ...state,
                editingForm: action.payload,
            }
        }
        case REDUX_FORM_SET_NAVIGATION_MODE_SITE: {
            return {
                ...state,
                navigationMode: action.payload,
            }
        }
        case REDUX_FORM_SET_PAGE_LIST_SITE: {
            return {
                ...state,
                portfolioObject: {
                    ...state.portfolioObject,
                    pages: action.payload,
                },
            }
        }
        case REDUX_FORM_SET_PARENT_ACTIVE_SITE: {
            return {
                ...state,
                parentContent: action.payload,
            }
        }
        case REDUX_FORM_SET_SECTION_COLLAPSE_SITE: {
            return {
                ...state,
                sectionCollapse: action.payload,
            }
        }
        case REDUX_FORM_SET_SECTION_ID_SITE: {
            return {
                ...state,
                sectionId: action.payload,
            }
        }
        case REDUX_FORM_SHOW_EDIT_ALERT_SITE: {
            return {
                ...state,
                editFunction: action.payload.editFunction,
                editUrl: action.payload.editUrl,
                showEditAlert: action.payload.showEditAlert,
            }
        }
        default:
            return state
    }
}
