import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/br'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/ru'
import 'moment/locale/tr'

// data
import {
    languageIdObjectSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    getDateWithTypeSite,
} from 'services'

// main
export function getScheduleSite(
    reduxModalSite: reduxModalStateSite,
    startDate: string | undefined,
    endDate: string | undefined,
    dateOnly: boolean | undefined,
    date_type_choice?: 'd' | 'm' | 'y',
) {
    if (date_type_choice && date_type_choice !== 'd') {
        return getDateWithTypeSite(reduxModalSite, startDate, date_type_choice)
    }
    moment.locale('en') // default the locale to English
    const localStartDate = moment(startDate)
    const localEndDate = moment(endDate)

    if (reduxModalSite.languageId) {
        localStartDate.locale(languageIdObjectSite[reduxModalSite.languageId] || 'en')
        localEndDate.locale(languageIdObjectSite[reduxModalSite.languageId] || 'en')
    }

    // const todayYear = moment().format('YYYY')
    // const startYear = (localStartDate.format('YYYY'))
    // const startYearCheck = todayYear === startYear

    if (endDate && (localStartDate.format('D MMM YYYY')) === (localEndDate.format('D MMM YYYY'))) {
        if (dateOnly) {
            // if (startYearCheck) {
            //     return `${localStartDate.format('ddd D MMM')}`
            // }
            return `${localStartDate.format('ddd D MMM YYYY')}`
        }
        // if (startYearCheck) {
        //     return `${localStartDate.format('ddd D MMM - HH:mm')} - ${localEndDate.format('HH:mm')}`
        // }
        return `${localStartDate.format('ddd D MMM YYYY - HH:mm')} - ${localEndDate.format('HH:mm')}`
    }
    if (!dateOnly && endDate && (localEndDate.diff(localStartDate)) < 86700000) {
        // if (dateOnly) {
        // 	if (startYearCheck) {
        // 		return `${localStartDate.format('ddd D MMM')}`
        // 	}
        //     return `${localStartDate.format('ddd D MMM YYYY')}`
        // }
        // if (startYearCheck) {
        //     return `${localStartDate.format('ddd D MMM - HH:mm')} - ${localEndDate.format('HH:mm')}`
        // }
        return `${localStartDate.format('ddd D MMM YYYY - HH:mm')} - ${localEndDate.format('HH:mm')}`
    }
    if (endDate) {
        if (dateOnly) {
            // if (startYearCheck) {
            //     return `${localStartDate.format('ddd D MMM')} - ${localEndDate.format('ddd D MMM')}`
            // }
            return `${localStartDate.format('ddd D MMM YYYY')} - ${localEndDate.format('ddd D MMM YYYY')}`
        }
        // if (startYearCheck) {
        //     return `${localStartDate.format('ddd D MMM - HH:mm')} - ${localEndDate.format('ddd D MMM - HH:mm')}`
        // }
        return `${localStartDate.format('ddd D MMM YYYY - HH:mm')} - ${localEndDate.format('ddd D MMM YYYY - HH:mm')}`
    }
    if (dateOnly) {
        // if (startYearCheck) {
        //     return localStartDate.format('ddd D MMM')
        // }
        return localStartDate.format('ddd D MMM YYYY')
    }
    // if (startYearCheck) {
    //     return localStartDate.format('ddd D MMM - HH:mm')
    // }
    return localStartDate.format('ddd D MMM YYYY - HH:mm')
}
