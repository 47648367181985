// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    ButtonSite,
    ImageHelperSite,
    LoaderSite,
    TextInputSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
    Template763SiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    getResponsiveImageSite,
    getStyles,
    getTextSite,
    validateEmailSite,
} from 'services'

// props
type TemplateBlock763SiteProps = {
    blockId: string
    content: Template763SiteSerializer
    isEditHovered: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: any
}

// main
export const TemplateBlock763Site: React.FC<TemplateBlock763SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const locationHash = location.hash

    const deviceType = reduxModalSite.deviceType

    type contactErrorsType = {
        email?: string
        main_error?: string
        name?: string
        non_field_errors?: string
    }

    type contactFieldsType = {
        email: string
        name: string
    }

    const contactErrorsInitial = {
        email: '',
        main_error: '',
        name: '',
        non_field_errors: '',
    }

    const contactFieldsInitial = {
        email: '',
        name: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<contactErrorsType>(contactErrorsInitial)
    const [fields, setFields] = useState<contactFieldsType>(contactFieldsInitial)
    const [ipAddress, setIpAddress] = useState<any>()
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [selected, setSelected] = useState<any>()

    useEffect(() => {
        if (locationHash) {
            getConfirmVote()
        } else {
            getIpAddress()
        }
    }, [])

    function getConfirmVote() {
        try {
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) getConfirmVote')
            const getUrl = getApiUrlSite(`miscellanea/form/vote/confirmation/${locationHash.split('#')[1]}/`, reduxModalSite)
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getConfirmVote')
                    setIsConfirmed(true)
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getConfirmVote')
                    axiosErrorHandlerSite({
                        apiUrl: getUrl,
                        component: 'TemplateBlock763Site',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock763Site',
                'getConfirmVote',
            ))
        }
    }

    function getIpAddress() {
        try {
            const getUrl = 'https://get.geojs.io/v1/ip.json'
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIpAddress(response.data.ip)
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock763Site',
                'getIpAddress',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setButtonDisabled(false)
            setIsSuccess(false)
            setFields({
                ...fields,
                [event.name]: event.value,
            })
            setErrors({})  // should be after setFields to avoid bug on TextArea
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock763Site',
                'handleInputChange',
            ))
        }
    }

    function handleSubmit() {
        try {

            if (!selected) {
                setButtonDisabled(true)
                setErrors({
                    main_error: 'Please select a film to vote for',
                })
                return
            }

            if (!validateEmailSite(fields.email)) {
                setButtonDisabled(true)
                setErrors({
                    email: reduxText[7490],
                    main_error: reduxText[7534],
                })
                return
            }

            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) handleSubmit')

            const formData = new FormData()
            formData.append('email', fields.email.toLowerCase().trim())
            formData.append('ip_address', ipAddress)
            formData.append('name', fields.name)
            formData.append('vote_group', '2')  // TODO check
            formData.append('vote_selection', selected)

            console.log('selected', selected);


            // Axios
            const postUrl = getApiUrlSite(`miscellanea/form/vote/create/`, reduxModalSite)
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsSuccess(true)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleSubmit')
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleSubmit')
                    setErrors(error.response.data)
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'TemplateBlock763Site',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleSubmit',
                        skipSendErrorStatusCode: [400],
                    })
                })
        } catch (error) {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleSubmit')
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock763Site',
                'handleSubmit',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={isEditHovered ? 'is-edit-hovered' : ''}
        >
            <div
                className={`template-block-763 ${deviceType}${reduxModalSite.isWindows}`}
                style={getStyles(reduxModalSite, object.id, styles, 'template-block-763')}
            >
                {locationHash && isConfirmed && (
                    <p className='t-763-confirm-message'>{getTextSite(content.text3, reduxModalSite)}</p>
                )}
                {!locationHash && (
                    <React.Fragment>
                        <div
                            className={`t-763-selection-wrap ${deviceType}${reduxModalSite.isWindows}`}
                            style={getStyles(reduxModalSite, object.id, styles, 't-763-selection-wrap')}
                        >
                            <IonRadioGroup
                                onIonChange={(e) => {
                                    setSelected(e.detail.value)
                                    setButtonDisabled(false)
                                }}
                            >
                                {content.selection?.map((val) => {
                                    return (
                                        <IonItem
                                            key={val.id}
                                            className='t-763-selection'
                                            lines='none'
                                            mode='md'
                                        >
                                            <ImageHelperSite
                                                alt={getTextSite(val.content_object?.name, reduxModalSite)}
                                                className={`t-763-image ${deviceType}`}
                                                dominant_color={val.content_object?.get_dominant_color}
                                                src={getResponsiveImageSite(reduxModalSite, val.content_object!, 'get_image_site_mq')}
                                                styles={getStyles(reduxModalSite, object.id, styles, 't-763-image')}
                                            />
                                            <IonLabel>
                                                <p className='t-763-selection-name'>{val.content_object?.name}</p>
                                            </IonLabel>
                                            <IonRadio
                                                slot='start'
                                                value={val.id}
                                            />
                                        </IonItem>
                                    )
                                })}
                            </IonRadioGroup>
                        </div>
                        <div
                            className={`t-763-submit-wrap ${deviceType}${reduxModalSite.isWindows}`}
                            style={getStyles(reduxModalSite, object.id, styles, 't-763-submit-wrap')}
                        >
                            <div
                                className={`t-763-description ${deviceType}${reduxModalSite.isWindows}`}
                                dangerouslySetInnerHTML={{ __html: content.text_html }}
                            />
                            {isSuccess
                                ? (
                                    <React.Fragment>
                                        <p className='t-763-success-message'>{getTextSite(content.text2, reduxModalSite)}</p>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <TextInputSite
                                            error={errors?.name}
                                            label={reduxText[7613]}
                                            name='name'
                                            onChange={(e: any) => handleInputChange(e)}
                                            styles={getStyles(reduxModalSite, object.id, styles, 't-763-input')}
                                            stylesLabel={getStyles(reduxModalSite, object.id, styles, 't-763-label')}
                                            value={fields.name || ''}
                                        />
                                        <TextInputSite
                                            error={errors?.email}
                                            label={reduxText[7477]}
                                            name='email'
                                            onChange={(e: any) => handleInputChange(e)}
                                            styles={getStyles(reduxModalSite, object.id, styles, 't-763-input')}
                                            stylesLabel={getStyles(reduxModalSite, object.id, styles, 't-763-label')}
                                            value={fields.email || ''}
                                        />
                                        <div className='cfw-submit'>
                                            <ButtonSite
                                                buttonCssClass='t-763-button'
                                                buttonStyle={getStyles(reduxModalSite, object.id, styles, 't-763-button')}
                                                buttonWrapCssClass='t-763-button-wrap'
                                                buttonWrapStyle={getStyles(reduxModalSite, object.id, styles, 't-763-button-wrap')}
                                                deviceType={deviceType}
                                                disabled={buttonDisabled}
                                                hoverStyle={getStyles(reduxModalSite, object.id, styles, 't-763-button-hover')}
                                                onClick={() => handleSubmit()}
                                                text={getTextSite(content.text, reduxModalSite)}
                                            />
                                            <div className='t-763-error-message'>{errors?.main_error || errors?.non_field_errors}</div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </React.Fragment>
                )}
                <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'TemplateBlock763Site' : ''} />
            </div>
            <EditBlockSite
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
