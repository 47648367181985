// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonChip,
    IonLabel,
} from '@ionic/react'
import Geosuggest from 'react-geosuggest'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CityTempSerializer,
} from 'serializers/site'

// props
type LocationInput819SiteProps = {
    disabled?: boolean
    error: string | undefined
    label: string
    multi?: boolean
    name: string
    onChange: any
    placeholder?: string
    value: CityTempSerializer[] | ''
}

// main
export const LocationInput819Site: React.FC<LocationInput819SiteProps> = React.memo(({
    disabled,
    error,
    label,
    multi,
    name,
    onChange,
    placeholder,
    value,
}) => {

    const dispatch = useDispatch()
    const geosuggestEl = useRef<Geosuggest>(null)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [newValue, setValue] = useState<CityTempSerializer[]>(value || [])

    useEffect(() => {
        const scriptId = 'googleMaps'
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script')
            script.async = true
            script.type = 'text/javascript'
            script.id = scriptId
            // script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBKkLm9Tjf3AsGHnECrN0hX1WTSUE6I2bM&libraries=places'  // TODO AGSL dynamic
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBdMtt5UB-C5WxbxEHN3v09Y2sb49LjzRM&libraries=places'  // TODO AGSL dynamic
            document.body.appendChild(script)
        }
    }, [])

    function onClear() {
        geosuggestEl?.current?.clear()
    }

    function onSelectRemove(placeId: any) {
        try {
            const toSendValue: CityTempSerializer[] = []
            newValue.map((val) => {
                if (val.placeId !== placeId) toSendValue.push(val)
                return false
            })
            setValue(toSendValue)
            onChange({
                name: name,
                value: toSendValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'LocationInput819Site',
                'onSelectRemove',
            ))
        }
    }

    function onSuggestSelect(response: any) {
        if (process.env.NODE_ENV === 'development') console.log(response)
        const gmaps: google.maps.places.PlaceResult = response ? response.gmaps : ''
        if (gmaps?.address_components) {
            let areaLevel1
            let areaLevel2
            let countryCode
            let countryName
            let cityName
            let postalCode
            let route
            let streetNumber
            const location = (gmaps?.geometry?.location) as google.maps.LatLng
            gmaps.address_components.map((object) => {
                if (object.types[0] === 'administrative_area_level_1') {
                    areaLevel1 = object.short_name
                }
                if (object.types[0] === 'administrative_area_level_2') {
                    areaLevel2 = object.short_name
                }
                if (object.types[0] === 'country') {
                    countryCode = object.short_name
                    countryName = object.long_name
                }
                if (object.types[0] === 'locality') {
                    cityName = object.short_name
                }
                if (object.types[0] === 'postal_code') {
                    postalCode = object.short_name
                }
                if (['route', 'town_square'].includes(object.types[0])) {
                    route = object.long_name
                }
                if (object.types[0] === 'street_number') {
                    streetNumber = object.long_name
                }
                return false
            })
            const latValue = location.lat()
            const lngValue = location.lng()
            const toReturnObject: CityTempSerializer = {
                areaLevel1: areaLevel1,
                areaLevel2: areaLevel2,
                cityName: cityName,
                countryCode: countryCode,
                countryName: countryName,
                description: response ? response.description : '',
                latValue: latValue,
                lngValue: lngValue,
                placeId: gmaps.place_id || '',
                postalCode: postalCode,
                route: route,
                streetNumber: streetNumber,
            }

            if (multi) {
                let toSendValue: CityTempSerializer[] = []
                const index = newValue.findIndex((x: CityTempSerializer) => x.placeId === gmaps.place_id)
                if (index === -1) {
                    toSendValue = [...newValue, toReturnObject]
                    setValue(toSendValue)
                }
                onChange({
                    name: name,
                    value: toSendValue,
                })
            } else {
                setValue([toReturnObject])
                onChange({
                    name: name,
                    value: [toReturnObject],
                })
            }
        }
        onClear()
    }

    return (
        <div className={`input-819 location-input-819 ${deviceType}`}>
            <span className={`input-819-label ${deviceType}`}>{label}</span>
            <Geosuggest
                disabled={disabled}
                onSuggestSelect={onSuggestSelect}
                placeDetailFields={['address_components', 'place_id']}
                placeholder={placeholder}
                ref={geosuggestEl}
            />
            {value && value.length > 0 && (
                <div className='li-819-chips'>
                    {value.map((data, i) => (
                        <IonChip key={i}>
                            <IonLabel>{data.description}</IonLabel>
                            <i
                                className='mo-new-icon-circle-xmark-regular li-819-cross mo-cursor'
                                onClick={() => onSelectRemove(data.placeId)}
                            />
                        </IonChip>
                    ))}
                </div>
            )}
            {error && <span className='input-819-error'>{error}</span>}
        </div>
    )
})
