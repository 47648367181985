// serializers
import {
    MainIdNameSerializer,
} from 'serializers/site'

// data
import {
    reduxModalStateSite,
    reduxTextStateSite,
} from 'data'

// services
import {
    getDateWithTypeSite,
    getScheduleSite,
} from 'services'

// main
export function getTextSite(
    text: string | MainIdNameSerializer[] |
    {
        date: string
        date_type_choice: string
        date_format?: string
        date_format_y?: string
        date_format_m?: string
        date_format_d?: string
    } |
    {
        date_only: boolean
        end_date: string
        start_date: string
    } |
    {
        is_schedule: boolean
        schedule: {
            date_only: boolean
            end_date: string
            start_date: string
        }[]
    } |
    {
        is_redux: boolean
        text_id: number
    } |
    {
        is_rich: boolean
        value: string
    } |
    {
        id: string
        disabled: boolean
        value: string
    } |
        undefined,
    reduxModalSite: reduxModalStateSite,
    reduxText?: reduxTextStateSite['data'],
    seperation?: string,
) {
    if (typeof text === 'string' || typeof text === 'number') return text
    if (Array.isArray(text)) {
        if (seperation === 'new-line') {
            return text.map((val) => val.name).join(' \n ')
        }
        return text.map((val) => val.name).join(` ${seperation || '-'} `)
    }
    // @ts-ignore
    if (text?.date) return getDateWithTypeSite(reduxModalSite, text.date, text.date_type_choice, text.date_format, text.date_format_y, text.date_format_m, text.date_format_d) || ''
    // @ts-ignore
    if (text?.start_date) return getScheduleSite(reduxModalSite, text.start_date, text.end_date, text.date_only, text.date_type_choice) || ''
    // @ts-ignore
    if (text?.is_schedule) return text.schedule?.map((val) => getScheduleSite(reduxModalSite, val.start_date, val.end_date, val.date_only) || '').join('\n')
    // @ts-ignore
    if (text?.is_redux) return reduxText[text.text_id]
    // @ts-ignore
    if (text?.value) return text.value
    return ''
}
