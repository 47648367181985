// react components
import React from 'react'
import {
    isValid,
    parseISO,
} from 'date-fns'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalStateSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldHelperSerializer,
} from 'serializers/site'

// props
type TemplateBlock797DateTimeSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldHelperSerializer | undefined
    format: 'date' | 'datetime' | 'time'
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: any
}

// main
export const TemplateBlock797DateTimeSite: React.FC<TemplateBlock797DateTimeSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    format,
    handleInputChange,
    isEditHovered,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {

    const dispatch = useDispatch()

    function onInputChange(e: any) {
        try {
            let dateFormat
            if (e) {
                if (format === 'time') {
                    dateFormat = moment(e).format('HH:mm')
                } else if (format === 'datetime') {
                    dateFormat = moment(e).format()
                } else {
                    dateFormat = moment(e).format('YYYY-MM-DD')
                }
            } else {
                dateFormat = ''
            }
            handleInputChange(formInputObj?.name!, dateFormat, formInputObj?.label!, formInputObj?.stripe_field_type)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797DateTimeSite',
                'onInputChange',
            ))
        }
    }

    if (format === 'date') {
        return (
            <div style={stylesNew}>
                <DatePicker
                    id={blockId}
                    className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                    dateFormat={'dd/MM/yyyy'}
                    onChange={date => onInputChange(date)}
                    placeholderText={formInputObj?.placeholder === 'true' ? (formInputObj?.translations?.find((obj) => obj.language_id === reduxModalSite.languageId)?.value || formInputObj?.label) : ''}
                    popperClassName='di-popper'
                    selected={isValid(parseISO(value)) ? parseISO(value) : null}
                    tabIndex={tabIndex}
                />
            </div>
        )
    }

    if (format === 'datetime') {
        return (
            <div style={stylesNew}>
                <DatePicker
                    id={blockId}
                    className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                    dateFormat='dd/MM/yyyy HH:mm'
                    onChange={date => onInputChange(date)}
                    placeholderText={formInputObj?.placeholder === 'true' ? (formInputObj?.translations?.find((obj) => obj.language_id === reduxModalSite.languageId)?.value || formInputObj?.label) : ''}
                    popperClassName='di-popper'
                    selected={isValid(parseISO(value)) ? parseISO(value) : null}
                    showTimeSelect
                    tabIndex={tabIndex}
                    timeFormat='HH:mm'
                    // @ts-ignore
                    style={stylesNew}
                />
            </div>
        )
    }

    if (format === 'time') {
        return (
            <div style={stylesNew}>
                <DatePicker
                    id={blockId}
                    className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                    dateFormat='HH:mm'
                    onChange={date => onInputChange(date)}
                    placeholderText={formInputObj?.placeholder === 'true' ? (formInputObj?.translations?.find((obj) => obj.language_id === reduxModalSite.languageId)?.value || formInputObj?.label) : ''}
                    popperClassName='di-popper'
                    selected={(value && !['Invalid date'].includes(value)) ? new Date(2024, 1, 1, value.split(':')[0], value.split(':')[1]) : null}
                    showTimeSelect
                    showTimeSelectOnly
                    tabIndex={tabIndex}
                    timeFormat='HH:mm'
                />
            </div>
        )
    }

    return null
})
