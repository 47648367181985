// react components
import React from 'react'

// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldHelperSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock797CheckboxMultipleSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldHelperSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
}

// main
export const TemplateBlock797CheckboxMultipleSite: React.FC<TemplateBlock797CheckboxMultipleSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    handleInputChange,
    isEditHovered,
    reduxModalSite,
    stylesNew,
    tabIndex,
}) => {
    return (
        <div
            id={blockId}
            className={`template-block-797 checkboxmultiple${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            {formInputObj?.options?.map((val) => (
                <div
                    key={val.id}
                    style={formInputObj.styles_797 ? getStylesNew(reduxModalSite, formInputObj.styles_797, undefined) : stylesNew}
                >
                    <input
                        name={formInputObj?.name}
                        onChange={(e) => handleInputChange(formInputObj?.name!, e.target.checked, formInputObj?.label!, formInputObj?.stripe_field_type, val.value)}
                        // tabIndex={tabIndex}
                        type='checkbox'
                        value={val.value}
                    />
                    <span className='t-797-checkboxmultiple-label'>{val.value}</span>
                </div>
            ))}
        </div>
    )
})
