// react components
import React, {
    useState,
} from 'react'
import {
    useStripe,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    api_url_portfolio_sales_stripe_create,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    Template819SiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getApiUrlSiteV2,
    getAxiosHeadersSite,
} from 'services'

// props
type StripeHelper819SiteProps = {
    content: Template819SiteSerializer | undefined
    setMainErrors?: React.Dispatch<any>
}

// main
export const StripeHelper819Site: React.FC<StripeHelper819SiteProps> = React.memo(({
    content,
    setMainErrors,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)
    const stripe = useStripe()

    const deviceType = reduxModalSite.deviceType
    const contactInfo = reduxSalesSite.activityData?.contactInfo

    const [isLoading, setIsLoading] = useState(false)

    function checkAvailability() {
        try {
            setIsLoading(true)
            const course_to_check: {
                course: number
                quantity: number
            }[] = []
            const sales_order_lines: any = []
            reduxSalesSite.activityData?.registrations && Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).map((val) => {
                [...Array(val.qty)].map((_, index) => {
                    course_to_check.push({
                        course: val.course.id!,
                        quantity: 1,
                    })
                    const extra_data_json = val.students?.[index]?.fields || {}
                    extra_data_json.course__id = val.course.id
                    sales_order_lines.push({
                        package_option_items: [],
                        extra_data_json: extra_data_json,
                        product: val.course.product!.id,
                        product_variant: val.variant.id,
                        quantity: 1,
                        unit_price: val.variant.unit_price,
                    })
                })
            })
            const groupedCourses = course_to_check.reduce((acc, curr) => {
                // @ts-ignore
                const foundCourse = acc.find(item => item.course === curr.course)
                if (foundCourse) {
                    // @ts-ignore
                    foundCourse.quantity += curr.quantity
                } else {
                    // @ts-ignore
                    acc.push({ ...curr })
                }
                return acc
            }, [])
            const postUrl = getApiUrlSiteV2('activity/helper/course/availability_check/', reduxModalSite)
            if (process.env.NODE_ENV === 'development') console.log(postUrl)
            const formData: any = new FormData()
            formData.append('course_to_check', JSON.stringify(groupedCourses))
            formData.append('sales_order_lines', JSON.stringify(sales_order_lines))
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    setIsLoading(false)
                    handleStripe()
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'StripeHelper819Site',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formData),
                        reduxAuth,
                        reduxModalSite,
                        reference: 'checkAvailability',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'StripeHelper819Site',
                'checkAvailability',
            ))
        }
    }

    function handleStripe() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) handleStripe')
            const sales_order_lines: any = []
            reduxSalesSite.activityData?.registrations && Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).map((val) => {
                [...Array(val.qty)].map((_, index) => {
                    const extra_data_json = val.students?.[index]?.fields || {}
                    extra_data_json.course__id = val.course.id
                    sales_order_lines.push({
                        package_option_items: [],
                        extra_data_json: extra_data_json,
                        product: val.course.product!.id,
                        product_variant: val.variant.id,
                        quantity: 1,
                        unit_price: val.variant.unit_price,
                    })
                })
            })
            const formData = {
                billing_first_name: contactInfo?.first_name || '',
                billing_last_name: contactInfo?.last_name || '',
                customer_email: contactInfo?.email || '',
                customer_first_name: contactInfo?.first_name || '',
                customer_last_name: contactInfo?.last_name || '',
                payment_method: reduxSalesSite.activityData?.paymentMethod?.id || 'stripe',
                portfolio: reduxCacheSite.portfolio?.id,
                sales_order_lines: sales_order_lines,
            }
            let postUrl = getApiUrlSite(api_url_portfolio_sales_stripe_create, reduxModalSite)
            postUrl += `?is_service=true`
            if (reduxSalesSite.activityData?.isInstallment) {
                postUrl += `&is_installement=true`
            }
            if (reduxModalSite.currencyId) {
                postUrl += `&currency_id=${reduxModalSite.currencyId}`
            }
            if (content?.data_json?.stripe_coupon_id) {
                postUrl += `&stripe_coupon_id=${content.data_json.stripe_coupon_id}`
            }
            if (content?.data_json?.stripe_recurrent_product_id) {
                postUrl += `&stripe_recurrent_product_id=${content.data_json.stripe_recurrent_product_id}`
            }
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    if (!reduxSalesSite.activityData?.paymentMethod?.id || reduxSalesSite.activityData.paymentMethod.id === 'stripe') {
                        stripe!.redirectToCheckout({
                            // Make the id field from the Checkout Session creation API response
                            // available to this file, so you can provide it as parameter here
                            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                            sessionId: response.data.stripe_session_id,
                        }).then((result: any) => {
                            if (process.env.NODE_ENV === 'development') console.log(result)
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, display the localized error message to your customer
                            // using `result.error.message`.
                        })
                    } else {
                        history.push(`${reduxModalSite.rootUrl || '/'}checkout-success/#transactionId=${response.data.unique_transaction_id}`)
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    // setMainErrors(error?.response?.data)
                    // thisState.setState({
                    // 	tabValue: 'payment-error',  // TODO
                    // })
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'StripeHelper819Site',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formData),
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleStripe',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'StripeHelper819Site',
                'handleStripe',
            ))
        }
    }

    if (Number(reduxSalesSite.activityData?.totalValue) === 0) {
        return (
            <React.Fragment>
                <button
                    className={`t-819-button-next disabled ${deviceType}`}
                    disabled
                >
                    <span>{reduxText[7466]}</span>
                </button>
                <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'StripeHelper819Site Number(reduxSalesSite.activityData?.totalValue) === 0' : ''} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <button
                className={`t-819-button-next ${deviceType}`}
                onClick={checkAvailability}
            >
                {(!reduxSalesSite.activityData?.paymentMethod?.id || reduxSalesSite.activityData.paymentMethod.id === 'stripe')
                    ? (
                        <span>Valider et payer</span>

                    ) : (
                        <span>Valider</span>
                    )
                }
                <i className={`t-819-icon mo-new-icon-chevron-right-solid ${deviceType}`} />
            </button>
            <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'StripeHelper819Site' : ''} />
        </React.Fragment>
    )
})
