// react components
import React from 'react'

// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldHelperSerializer,
    formInfoTypeSite,
} from 'serializers/site'

// services
import {
    getStylesNew,
    parseQuerySite,
} from 'services'

// props
type TemplateBlock797SelectSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInfo: formInfoTypeSite
    formInputObj: FormFieldHelperSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    location: any
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: any
}

// main
export const TemplateBlock797SelectSite: React.FC<TemplateBlock797SelectSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInfo,
    formInputObj,
    handleInputChange,
    isEditHovered,
    location,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {
    return (
        <select
            id={blockId}
            className={`template-block-797${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            defaultValue={parseQuerySite(location.search)?.[`defaultFields_${formInputObj?.name}`] || ''}
            name={formInputObj?.name}
            onChange={(e) => handleInputChange(formInputObj?.name!, e.target.value, formInputObj?.label!, formInputObj?.stripe_field_type)}
            style={formInputObj?.styles_797 ? getStylesNew(reduxModalSite, formInputObj?.styles_797, undefined) : stylesNew}
            // tabIndex={tabIndex}
            value={value}
        >
            <option
                disabled
                value=''
            >
                {formInputObj?.placeholder === 'true' ? (formInputObj?.translations?.find((obj) => obj.language_id === reduxModalSite.languageId)?.value || formInputObj?.label) : undefined}
            </option>
            {formInputObj?.options?.map((val) => (
                <option
                    key={val.id}
                    value={(formInfo?.form_is_external || formInfo?.form_is_subscription || (formInfo?.formSubmitType && !['miscellanea_contact', 'miscellanea_mail'].includes(formInfo?.formSubmitType))) ? val.id : val.value}
                >
                    {val.value}
                </option>
            ))}
            {formInputObj?.optgroups?.map((val) => (
                <optgroup
                    key={val.value}
                    label={val.value}
                >
                    {val.options?.map((val2) => (
                        <option
                            key={val2.id}
                            value={val2.value}
                        >
                            {val2.value}
                        </option>
                    ))}
                </optgroup>
            ))}
        </select>
    )
})
