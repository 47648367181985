// react components
import React from 'react'
import {
    useStripe,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    api_url_portfolio_contact_form,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    TemplateBlock783HelperSite,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template783SiteSerializer,
    formInfoTypeSite,
    formValueTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    validateEmailSite,
} from 'services'

// props
type TemplateBlock783StripeSiteProps = {
    blockId: string
    content: Template783SiteSerializer | undefined
    contentArray: Template783SiteSerializer[] | undefined
    formInfo: formInfoTypeSite
    formValue: formValueTypeSite
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    setFormValue: React.Dispatch<formValueTypeSite> | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock783StripeSite: React.FC<TemplateBlock783StripeSiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    formInfo,
    formValue,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)
    const stripe = useStripe()

    function submitPaymentForm() {
        try {
            const formData = new FormData()
            const postUrl = getApiUrlSite(api_url_portfolio_contact_form, reduxModalSite)
            formData.append('block_id', JSON.stringify({ key: 'block_id', label: 'block_id', value: `${formInfo?.id}` }))
            formData.append('from', JSON.stringify({ key: 'from', label: 'From', value: window.location.href }))
            const errorsData: string[] = []
            let validMailError = false
            formInfo?.fields?.map((val) => {
                if (val.required && !formValue?.fields[val.name!]?.value) {
                    errorsData.push(val.name!)
                } else if (val.type === 'email' && formValue?.fields[val.name!] && !validateEmailSite(formValue.fields[val.name!].value!)) {
                    setFormValue!({
                        ...formValue!,
                        errors: {
                            [val.name!]: reduxText[7490],
                            main_error: reduxText[7534],
                        },
                        helpers: {
                            ...formValue!.helpers,
                            buttonDisabled: true,
                        }
                    })
                    validMailError = true
                    errorsData.push(val.name!)
                }
                formData.append(val.name!, JSON.stringify(formValue?.fields[val.name!]))
                return false
            })
            if (validMailError) return
            if (errorsData.length > 0) {
                const newErrors: {
                    [key: string]: string
                } = {}
                newErrors.main_error = reduxText[7534]
                errorsData.map((val2) => {
                    newErrors[val2] = reduxText[7489]
                    return false
                })
                setFormValue!({
                    ...formValue!,
                    errors: newErrors,
                    helpers: {
                        ...formValue!.helpers,
                        buttonDisabled: true,
                    }
                })
            } else {
                setFormValue!({
                    ...formValue!,
                    helpers: {
                        ...formValue!.helpers,
                        buttonDisabled: true,
                        isLoading: true,
                    }
                })
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (response.data.stripe_session_id) {
                            setFormValue!({
                                ...formValue!,
                                helpers: {
                                    ...formValue!.helpers,
                                    isLoading: false,
                                }
                            })
                            handleStripe(response.data)
                        } else if (response.data.redirect_to_success) {
                            setFormValue!({
                                ...formValue!,
                                helpers: {
                                    ...formValue!.helpers,
                                    isLoading: false,
                                }
                            })
                            history.push(`${reduxModalSite.rootUrl}payment-success?custom_message_id=${response.data.custom_message_id}`)
                        } else {
                            setFormValue!({
                                ...formValue!,
                                errors: {},
                                fields: {},
                                helpers: {
                                    ...formValue!.helpers,
                                    buttonDisabled: true,
                                    isLoading: false,
                                    isSuccess: true,
                                    isSuccessText: content?.text2,
                                }
                            })
                        }
                    })
                    .catch((error) => {
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        setFormValue!({
                            ...formValue!,
                            errors: error.response?.data,
                            helpers: {
                                ...formValue!.helpers,
                                buttonDisabled: true,
                                isLoading: false,
                            }
                        })
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock783StripeSite',
                            dispatch,
                            error,
                            formFields: JSON.stringify(formValue?.fields),
                            reduxAuth,
                            reduxModalSite,
                            reference: 'submitPaymentForm',
                            skipAlertStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setFormValue!({
                ...formValue!,
                helpers: {
                    ...formValue!.helpers,
                    isLoading: false,
                }
            })
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783StripeSite',
                'submitPaymentForm',
            ))
        }
    }

    function handleStripe(responseData: any) {
        try {
            stripe!.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: responseData.stripe_session_id,
            }).then((result: any) => {
                if (process.env.NODE_ENV === 'development') console.log(result)
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            })

        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783StripeSite',
                'handleStripe',
            ))
        }
    }

    return (
        <TemplateBlock783HelperSite
            blockId={blockId}
            content={content}
            contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
            formInfo={formInfo}
            formValue={formValue}
            isEditHovered={isEditHovered}
            isInComponent={isInComponent}
            mainParentId={mainParentId}
            object={object}
            parentArray={parentArray}
            parentStyles={parentStyles}
            setFormValue={setFormValue}
            styles={styles}
            stylesEdit={stylesEdit}
            submitPayment={submitPaymentForm}
        />
    )
})
