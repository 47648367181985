// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    ImageHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxSalesCartToggleSite,
    reduxSalesCancelSite,
    reduxSalesSuccessSite,
} from 'data'

// props
type CheckoutResponseSiteProps = {
    hasContent: boolean
    pageSlug: string
    pageStyles: { [key: string]: string | undefined }
}

// main
export const CheckoutResponseSite: React.FC<CheckoutResponseSiteProps> = React.memo(({
    hasContent,
    pageSlug,
    pageStyles,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolio = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    useEffect(() => {
        if (pageSlug === 'checkout-success') {
            const transactionId = location.hash.split('=')[1]
            if (transactionId) {
                // @ts-ignore
                dispatch(reduxSalesSuccessSite(transactionId, reduxModalSite, reduxAuth))
            }
        } else if (pageSlug === 'checkout-cancel') {
            dispatch(reduxSalesCancelSite())
        }
    }, [
        location.hash,
    ])

    return (
        <div
            className={`checkout-response-site ${deviceType}`}
            style={{
                background: pageStyles.background || 'white',
                color: pageStyles.color || 'black',
            }}
        >
            {pageSlug === 'checkout-success' && (
                <div className='t-crs-confirmation'>
                    {!hasContent && (
                        <p className='t-crs-text'>{reduxText[7469]}</p>
                    )}
                    <p className='t-crs-text'>{reduxText[7470]}</p>
                    <div className='t-crs-products'>
                        {reduxSalesSite.soldResponse && (
                            <React.Fragment>
                                {reduxSalesSite.soldResponse?.sales_order_lines?.map((val, i) => (
                                    <div className='t-crs-lines' key={`t-crs-lines-${i}`}>
                                        <div className='t-crs-image-wrap'>
                                            <ImageHelperSite
                                                alt={val.product?.name}
                                                className='t-crs-image'
                                                dominant_color={undefined}
                                                src={val.product?.get_image_site_lq}
                                            />
                                        </div>
                                        <div className='t-crs-info'>
                                            <span className='t-crs-name'>{val.product?.name}</span>
                                            {val.product_variant && (
                                                <span className='t-crs-variant'>{val.product_variant.name}</span>
                                            )}
                                            {val.package_option_items?.map((val2) => (
                                                <span
                                                    key={val2.id}
                                                    className='t-crs-variant'>{val2.product?.name}
                                                </span>
                                            ))}
                                            <span className='t-crs-price'>{Number(val.quantity)?.toFixed()} x {val.unit_price} {reduxSalesSite.soldResponse?.currency}</span>
                                            <span className='t-crs-line-total'>{Number(Number(val.line_total).toFixed(2))} {reduxSalesSite.soldResponse?.currency}</span>
                                        </div>
                                    </div>
                                ))}
                                {reduxCacheSiteportfolio?.is_shipping_activated && (
                                    <>
                                        <p className='t-crs-shipping'>{reduxText[7467]} {Number(((reduxSalesSite.soldResponse?.total_amount || 0) - (reduxSalesSite.soldResponse?.shipping_cost || 0)).toFixed(2))} {reduxSalesSite.soldResponse?.currency}</p>
                                        <p className='t-crs-shipping'>{reduxText[8518]} {Number(Number(reduxSalesSite.soldResponse?.shipping_cost)?.toFixed(2))} {reduxSalesSite.soldResponse?.currency}</p>
                                    </>
                                )}
                                <p className='t-crs-subtotal'>{reduxText[7471]} {Number(Number(reduxSalesSite.soldResponse?.total_amount)?.toFixed(2))} {reduxSalesSite.soldResponse?.currency}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
            {pageSlug === 'checkout-cancel' && (
                <div className='t-crs-cancel'>
                    <p className='t-crs-text'>{reduxText[7472]}</p>
                    <span className='t-crs-button' onClick={() => dispatch(reduxSalesCartToggleSite(true))}>{reduxText[7473]}</span>
                </div>
            )}
        </div>
    )
})
