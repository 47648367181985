// react components
import React from 'react'
import axios from 'axios'
import {
    getPlatforms,
    isPlatform,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'

// data
import {
    app_version_site,
    errorMessageToSkipSite,
    reduxAuthLogoutSite,
    reduxAuthStateSite,
    reduxModalErrorShowHideSite,
    reduxModalStateSite,
} from 'data'

// services
import {
    getApiUrlSite,
} from 'services'

// main
export function axiosErrorHandlerSite({
    apiUrl,
    component,
    dispatch,
    error,
    formFields,
    reduxAuth,
    reduxModalSite,
    reference,
    skip401,
    skipAlert,
    skipAlertStatusCode,
    skipSendError,
    skipSendErrorStatusCode,
}: {
    apiUrl: string
    component: string
    dispatch: React.Dispatch<any>
    error: any
    formFields?: string
    reduxAuth: reduxAuthStateSite
    reduxModalSite: reduxModalStateSite
    reference: string
    skip401?: boolean
    skipAlert?: boolean
    skipAlertStatusCode?: number[]
    skipSendError?: boolean
    skipSendErrorStatusCode?: number[]
}) {

    // log
    if (process.env.NODE_ENV === 'development') console.log(error)
    if (process.env.NODE_ENV === 'development') console.log(`${error}`)
    if (process.env.NODE_ENV === 'development') console.log(`${component} - ${reference}`)
    if (process.env.NODE_ENV === 'development') console.log(error.response)
    if (error?.message === 'axios canceled') return

    // const
    const platform = isPlatform('ios') ? 'ios' : (isPlatform('android') ? 'android' : (isMobileOnly ? 'mobile' : 'web'))
    const responseStatus = error.response?.status

    // show alert and don't send
    if (!skipAlert && !(skipAlertStatusCode?.includes(responseStatus))) {
        if (errorMessageToSkipSite.includes(error?.message)) {
            dispatch(reduxModalErrorShowHideSite({
                header: 'Error', // TO_LATER_TEXT
                isOpen: true,
                message: error.message,
            }))
            return
        }
        if (errorMessageToSkipSite.includes(`${error}`)) {
            dispatch(reduxModalErrorShowHideSite({
                header: 'Error', // TO_LATER_TEXT
                isOpen: true,
                message: `${error}`,
            }))
            return
        }
    }

    if (!skipAlert && !(skipAlertStatusCode?.includes(responseStatus))) {
        if (responseStatus === 401 && !skip401) {
            if (process.env.NODE_ENV !== 'development') {
                dispatch(reduxAuthLogoutSite())
            }
            dispatch(reduxModalErrorShowHideSite({
                header: 'Please sign in again', // TO_LATER_TEXT
                isOpen: true,
            }))
        } else if (responseStatus) {
            if (responseStatus === 400 && error.response.data?.non_field_errors) {
                dispatch(reduxModalErrorShowHideSite({
                    header: responseStatus,
                    isOpen: true,
                    message: error.response.data.non_field_errors,
                    noMessage: true,
                    subHeader: error.response.statusText,
                }))
            } else {
                dispatch(reduxModalErrorShowHideSite({
                    header: responseStatus,
                    isOpen: true,
                    subHeader: error.response.statusText,
                }))
            }
        } else if (error.message) {
            dispatch(reduxModalErrorShowHideSite({
                header: 'Error',
                isOpen: true,
                message: error.message,
            }))
        } else {
            dispatch(reduxModalErrorShowHideSite({
                header: 'Error',
                isOpen: true,
                message: `${error}`,
            }))
        }
    }
    if (skipSendError || (skipSendErrorStatusCode?.includes(responseStatus))) return
    if (errorMessageToSkipSite.includes(error?.message)) return
    if (containsErrorMessageToSkip(`${error}`)) return
    if (process.env.NODE_ENV !== 'production') console.log('sending error log')
    if (process.env.NODE_ENV !== 'production') return
    // if (responseStatus === 403 && reduxAuth.settings?.user?.is_staff) return
    const createUrl = getApiUrlSite('main/error_logs4/create/', reduxModalSite)
    if (responseStatus) {
        const formData = new FormData()
        formData.append('api_url', apiUrl)
        formData.append('app_version', app_version_site)
        formData.append('component', component)
        if (formFields) {
            formData.append('data', `${JSON.stringify(error.response.data)} - ${formFields} - 1`)
        } else {
            formData.append('data', `${JSON.stringify(error.response.data)} - 2`)
        }
        formData.append('location', window.location.href)
        formData.append('platform', `site - ${platform}`)
        formData.append('reference', reference)
        formData.append('status', responseStatus)
        formData.append('statusText', error && (error.response.statusText || 'Other'))
        formData.append('user_agent', navigator?.userAgent)
        axios({
            data: formData,
            headers: reduxAuth?.axiosConfig?.headers,
            method: 'post',
            url: createUrl,
        })
            .catch((axiosError) => {
                if (process.env.NODE_ENV === 'development') console.log(axiosError.response)
            })
    } else if (error) {
        const formData = new FormData()
        formData.append('api_url', apiUrl)
        formData.append('app_version', app_version_site)
        formData.append('component', component)
        formData.append('data', `${error} - 3`)
        formData.append('location', window.location.href)
        formData.append('platform', `site - ${platform}`)
        formData.append('reference', reference)
        formData.append('status', '0')
        formData.append('statusText', 'Other')
        formData.append('user_agent', navigator?.userAgent)
        axios({
            data: formData,
            headers: reduxAuth?.axiosConfig?.headers,
            method: 'post',
            url: createUrl,
        })
            .catch((axiosError) => {
                if (process.env.NODE_ENV === 'development') console.log(axiosError.response)
            })
    }
}

export function axiosErrorHandlerSiteErrorBoundarySite(
    error: any,
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    component: string,
    info: string,
) {
    if (process.env.NODE_ENV === 'development') console.log(error)
    if (containsErrorMessageToSkip(`${error}`)) return
    if (process.env.NODE_ENV !== 'production') return
    const platform = isPlatform('ios') ? 'ios' : (isPlatform('android') ? 'android' : 'web')
    const createUrl = getApiUrlSite('main/error_logs4/create/', reduxModalSite)
    if (error) {
        const formData = new FormData()
        formData.append('app_version', app_version_site)
        formData.append('component', component)
        formData.append('data', `${error} - 3`)
        formData.append('location', window.location.href)
        formData.append('platform', `site - ${platform}`)
        formData.append('reference', `${info} - ${getPlatforms()}`)
        formData.append('status', '0')
        formData.append('statusText', 'event handler')
        formData.append('user_agent', navigator?.userAgent)
        axios({
            data: formData,
            headers: reduxAuth?.axiosConfig?.headers,
            method: 'post',
            url: createUrl,
        })
            .catch((axiosError) => {
                if (process.env.NODE_ENV === 'development') console.log(axiosError.response)
            })
    }
}

function containsErrorMessageToSkip(str: string) {
    for (const errorMessage of errorMessageToSkipSite) {
        if (str.includes(errorMessage)) {
            return true
        }
    }
    return false
}
