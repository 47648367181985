// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'
import Select from 'react-select'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// portfolio
import {
    MainIdNameSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getAxiosHeadersSite,
    parseQuerySite,
} from 'services'

// props
type PrivateFilterInputSiteProps = {
    apiUrl: string
    label: string
    multi: boolean
    name: string
    onChange: (e: any) => void
}

// main
export const PrivateFilterInputSite: React.FC<PrivateFilterInputSiteProps> = React.memo(({
    apiUrl,
    label,
    multi,
    name,
    onChange,
}) => {

    useEffect(() => {
        getData()
    }, [
        apiUrl,
    ])

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [newOptions, setNewOptions] = useState<MainIdNameSerializer[]>([])
    const [newValue, setValue] = useState<any>()

    function getData() {
        try {
            if (process.env.NODE_ENV === 'development') console.log(apiUrl)
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: apiUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response.data)
                    setNewOptions(response.data)
                    setValue(getInitialValue(response.data))
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl,
                        component: 'PrivateFilterInputSite',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateFilterInputSite',
                'getData',
            ))
        }
    }

    function getInitialValue(listArray: MainIdNameSerializer[]) {
        try {
            const searchObject = parseQuerySite(location.search)
            const filter_query = searchObject.filter_query
            const initialId = filter_query?.split(name)[1]?.split('-')[1]
            if (initialId) {
                return listArray.find((obj) => obj.id === Number(initialId))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateFilterInputSite',
                'getInitialValue',
            ))
        }
    }

    function onInputChange(value: MainIdNameSerializer) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateFilterInputSite',
                'onInputChange',
            ))
        }
    }

    const optionColorActive = 'white'
    const optionBackgroundActive = '#ed1550'

    const customStyles = {
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? optionColorActive : 'black',
            ':hover': {
                ...provided[':hover'],
                backgroundColor: optionBackgroundActive,
                color: optionColorActive,
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
        }),
    }

    return (
        <div className={`private-filter-input-site ${deviceType}`}>
            <Select
                getOptionLabel={option => option.name}
                getOptionValue={option => `${option.id}`}
                isClearable={true}
                isMulti={multi}
                name={name}
                onChange={onInputChange}
                options={newOptions}
                placeholder={label}
                styles={customStyles}
                value={newValue}
            />
        </div>
    )
})
