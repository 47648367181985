// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'
import Select from 'react-select'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template822SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

// props
type TemplateBlock822SiteProps = {
    blockId: string
    content: Template822SiteSerializer | undefined
    isEditHovered: boolean
    object: PortfolioPageContentListSiteSerializer
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock822Site: React.FC<TemplateBlock822SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    object,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock822Site',
                'applyStyles',
            ))
        }
    }

    function onInputChange(e: any) {
        try {
            if (!e) return
            history.push(`${reduxModalSite.rootUrl || '/'}${e.absolute_site_url}`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock822Site',
                'onInputChange',
            ))
        }
    }

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            padding: '0 8px',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: stylesNew?.background || 'transparent',
            border: stylesNew?.borderWidth ? 'undefined' : 'none',
            borderColor: stylesNew?.borderColor,
            borderRadius: stylesNew?.borderRadius,
            borderStyle: stylesNew?.borderStyle,
            borderWidth: stylesNew?.borderWidth,
            color: stylesNew?.color,
            fontSize: stylesNew?.fontSize,
            padding: stylesNew?.padding,
        }),
        dropdownIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            padding: '0 0 0 8px',
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            ...provided,
            margin: 0,
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: stylesNew?.color || 'black',
            margin: 0,
            padding: 0,
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            ':hover': {
                ...provided[':hover'],
                cursor: 'pointer',
            },
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
            color: stylesNew?.placeholderColor || 'black',
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: stylesNew?.color || 'black',
            margin: 0,
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            padding: 0,
        }),
    }

    const NoOptionsMessage = (props: any) => {
        if (content?.no_options_page_url) {
            return (
                <a href={`${reduxModalSite.rootUrl || '/'}${content.no_options_page_url}`}>
                    <p
                        style={{
                            fontSize: stylesNew?.fontSize,
                            padding: stylesNew?.padding,
                        }}
                    >
                        {content?.no_options_message}
                    </p>
                </a>
            )
        }
        return (
            <p
                style={{
                    fontSize: stylesNew?.fontSize,
                    padding: stylesNew?.padding,
                }}
            >
                {content?.no_options_message}
            </p>
        )
    }

    const customComponents = {
        NoOptionsMessage,
    }

    return (
        <Select
            id={blockId}
            className={`template-block-822${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            components={customComponents}
            // @ts-ignore
            getOptionLabel={option => option?.text}
            // @ts-ignore
            getOptionValue={option => option?.absolute_site_url}
            isClearable={true}
            name='TODO'
            noOptionsMessage={content?.no_options_message ? (inputValue) => <NoOptionsMessage inputValue={inputValue} /> : undefined}
            onChange={onInputChange}
            options={content?.data}
            placeholder={content?.placeholder}
            styles={customStyles}
            value={undefined}
        />
    )
})
