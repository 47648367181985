// serializers
import {
    MainFontSerializer,
} from 'serializers/site'

// main
export function getGoogleFontsSite(fonts: MainFontSerializer[] | undefined) {
    const fontArray: string[] = []
    if (fonts?.length! > 0) {
        fonts?.map((val) => {
            if (val.category !== 'manual') fontArray.push(`${val.name}:wght@100;200;300;400;500;600;700;800;900`)
            return false
        })
        return fontArray
    }
    return ['Roboto:wght@100;200;300;400;500;600;700;800;900']
}
