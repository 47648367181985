// Root Url
const rootPath = 'portfolio/site/'

// Auth
export const api_url_auth_autorisation_check_site = 'auth/form/autorisation_check/'
export const api_url_auth_password_confirm_site = 'auth/form/user/password_confirm/'
export const api_url_auth_password_reset_site = 'auth/form/user/password_reset/'
export const api_url_auth_password_verification_code_site = 'auth/form/user/password_verification_code/'
export const api_url_auth_settings_detail_site = 'auth/detail/settings/'
export const api_url_auth_token_convert_site = 'auth/form/token/convert/'
export const api_url_auth_token_obtain_site = 'auth/form/token/obtain/'
export const api_url_auth_user_form_confirm_site = 'auth/form/user/confirm_new/'
export const api_url_auth_user_form_create_site = 'auth/form/user/create/'
export const api_url_auth_user_form_last_login_site = 'auth/form/user/last_login/'

// Detail
export const api_url_portfolio_detail_id_site = `${rootPath}detail/portfolio/id/`
export const api_url_portfolio_detail_site = `${rootPath}detail/portfolio/`
export const api_url_portfolio_hub_content_detail_site = `${rootPath}detail/hub_content/`
export const api_url_portfolio_page_detail_site = `${rootPath}detail/page/`

// Map
export const api_url_portfolio_hub_content_filter_site = `${rootPath}filter/hub_content/`

// Form
export const api_url_portfolio_contact_form = `${rootPath}form/contact/`
export const api_url_portfolio_newsletter_form = `${rootPath}form/newsletter/`
export const api_url_portfolio_payment_confirm_form = `${rootPath}form/payment_confirm/`
export const api_url_portfolio_sales_detail = 'sales/stripe/portfolio_sales/detail/'
export const api_url_portfolio_sales_paypal_create = 'sales/paypal/portfolio_sales/create/'
export const api_url_portfolio_sales_stripe_create = 'sales/stripe/portfolio_sales/create/'
export const api_url_portfolio_submit_form = `${rootPath}form/submit/`

// List
export const api_url_main_text_site = 'main/detail/text/5/'
export const api_url_portfolio_content_list_site = `${rootPath}list/page_content/`
export const api_url_portfolio_hub_content_list_site = `${rootPath}list/hub_content/`
export const api_url_portfolio_hub_content_filter_list_site = `${rootPath}list/hub_content_filter/`
// export const api_url_portfolio_upsell_items_list_site = 'sales/list/related_product/portfolio-upsell/'

// Map
export const api_url_portfolio_hub_content_map_site = `${rootPath}map/hub_content/`

// Shipping
export const api_url_shipping_cost_form_cost_calculation_site = 'sales/form/shipping_cost/cost_calculation/'

// Select
export const api_url_main_country = 'main/select/country/'
export const api_url_shipping_country = `${rootPath}select/country/`

// Stripe
export const api_url_stripe_subscription_customer_create_web = `sales/stripe/subscription/customer/create/`

// TODO
export const api_url_project_mixed_media_list_site = `project/list/mixed_media/`
