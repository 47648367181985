// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesTicketSite,
} from 'data'

// serializers
import {
    Variant816HelperSiteSerializer,
} from 'serializers/site'

// props
type ParticipantHelper816SiteProps = {
    index: number
    object: {
        index: number
        variant: Variant816HelperSiteSerializer
        qty: number
    }
}

// main
export const ParticipantHelper816Site: React.FC<ParticipantHelper816SiteProps> = React.memo(({
    index,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const firstName = reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.firstName || ''
    const lastName = reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index]?.lastName || ''

    function onSetName(value: React.ChangeEvent<HTMLInputElement>) {
        try {
            const toReturnData = {
                ...reduxSalesSite.ticketData,
                variants: {
                    ...reduxSalesSite.ticketData?.variants,
                    [object.index]: {
                        ...reduxSalesSite.ticketData?.variants?.[object.index],
                        participants: {
                            ...reduxSalesSite.ticketData?.variants?.[object.index]?.participants,
                            [index]: {
                                ...reduxSalesSite.ticketData?.variants?.[object.index]?.participants?.[index],
                                [value.target.name]: value.target.value,
                            },
                        },
                    },
                },
            }
            // @ts-ignore
            dispatch(reduxSalesTicketSite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ParticipantHelper816Site',
                'onSetName',
            ))
        }
    }

    return (
        <div className={`t-816-participant ${deviceType}`}>
            <div className={`t-816-participant-wrap ${deviceType}`}>
                <div className={`t-816-participant-title ${deviceType}`}>
                    <i className={`t-816-icon mo-new-icon-user-solid ${deviceType}`} />
                    <span>{reduxText[8747]} {index + 1}</span>
                </div>
                <div className={`t-816-participant-inputs ${deviceType}`}>
                    <div className={`t-816-participant-input-wrap ${deviceType}`}>
                        <span className={`t-816-participant-label ${deviceType}`}>{reduxText[8744]}</span>
                        <input
                            className={`t-816-participant-input ${deviceType}`}
                            name='firstName'
                            onChange={(e) => onSetName(e)}
                            value={firstName}
                        />
                    </div>
                    <div className={`t-816-participant-input-wrap ${deviceType}`}>
                        <span className={`t-816-participant-label ${deviceType}`}>{reduxText[8745]}</span>
                        <input
                            className={`t-816-participant-input ${deviceType}`}
                            name='lastName'
                            onChange={(e) => onSetName(e)}
                            value={lastName}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})
