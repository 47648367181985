// react components
import React, {
    useState,
} from 'react'
import {
    useStripe,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    api_url_stripe_subscription_customer_create_web,
    defaultReduxState,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// props
type ButtonHelper810SiteProps = {
    amount: number
    buttonStyles: any
    buttonText: string | undefined
    email: string
    isRecurent: boolean
    onHover: any
    productId: string | undefined
    setAmountError: React.Dispatch<string>
    setEmailError: React.Dispatch<string>
}

// main
export const ButtonHelper810Site: React.FC<ButtonHelper810SiteProps> = React.memo(({
    amount,
    buttonStyles,
    buttonText,
    email,
    isRecurent,
    onHover,
    productId,
    setAmountError,
    setEmailError,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const stripe = useStripe()

    const [isLoading, setIsLoading] = useState(false)

    function handleCheckData() {
        try {
            let checkOk = true
            if (!amount || Number(amount) <= 0) {
                checkOk = false
                setAmountError(reduxText[7489])
            }
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!re.test(email)) {
                checkOk = false
                setEmailError(reduxText[7490])
            }
            return checkOk
        } catch (error) {
            axiosErrorHandlerSite({
                apiUrl: 'apiUrl',
                component: 'ButtonHelper810Site',
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'handleCheckData',
            })
        }
    }

    function handleStripe() {
        try {
            if (!handleCheckData()) return
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) handleStripe')
            const formData = new FormData()
            formData.append('amount', Number(amount).toFixed(2))
            formData.append('path', `https://${window.location.hostname}${window.location.pathname}`)
            formData.append('product_id', productId!)
            formData.append('reference_email', email)
            if (isRecurent) {
                formData.append('mode', 'subscription')
            }
            const postUrl = getApiUrlSite(`${api_url_stripe_subscription_customer_create_web}${reduxCacheSite.portfolio?.hostname_slug}/`, reduxModalSite)
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    stripe!.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: response.data.id,
                    }).then((result: any) => {
                        if (process.env.NODE_ENV === 'development') console.log(result)
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) handleStripe')
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'ButtonHelper810Site',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleStripe',
                    })
                })
        } catch (error) {
            axiosErrorHandlerSite({
                apiUrl: 'postUrl',
                component: 'ButtonHelper810Site',
                dispatch,
                error,
                reduxAuth,
                reduxModalSite,
                reference: 'handleStripe',
            })
        }
    }

    return (
        <React.Fragment>
            <button
                className={`t-810-button ${deviceType}`}
                disabled={isLoading}
                onClick={() => handleStripe()}
                onMouseLeave={() => onHover('leave')}
                onMouseOver={() => onHover('over')}
                style={buttonStyles}
                type='button'
            >
                {buttonText || reduxText[8424]}
            </button>
            {isLoading && (
                <LoaderSite isOpen message={process.env.NODE_ENV === 'development' ? 'ButtonHelper810Site' : ''} />
            )}
        </React.Fragment>
    )
})
