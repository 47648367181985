// react components
import React from 'react'
import PhoneInput from 'react-phone-number-input'

// styles
import 'react-phone-number-input/style.css'

// data
import {
    reduxModalStateSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldHelperSerializer,
} from 'serializers/site'

// props
type TemplateBlock797PhoneNumberSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInputObj: FormFieldHelperSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: any
}

// main
export const TemplateBlock797PhoneNumberSite: React.FC<TemplateBlock797PhoneNumberSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInputObj,
    handleInputChange,
    isEditHovered,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {
    return (
        <PhoneInput
            id={blockId}
            className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            defaultCountry={reduxModalSite.portfolio?.id === 594 ? 'BE' : 'FR'}  // TODO dynamic
            onChange={(e) => handleInputChange(formInputObj?.name!, e, formInputObj?.label!, formInputObj?.stripe_field_type)}
            placeholder={formInputObj?.placeholder === 'true' ? (formInputObj?.translations?.find((obj) => obj.language_id === reduxModalSite.languageId)?.value || formInputObj?.label) : ''}
            style={stylesNew}
            tabIndex={tabIndex}
            value={value || ''}
        />
    )
})
