export const languageObjectSite: {
    [key: string]: number
} = {
    'ar': 19,
    'br': 60,
    'de': 149,
    'en': 1,
    'es': 409,
    'fr': 2,
    'it': 199,
    'nl': 112,
    'pl': 348,
    'pt': 349,
    'ru': 364,
    'tr': 444,
}

export const languageIdObjectSite: {
    [key: number]: string
} = {
    1: 'en',
    2: 'fr',
    19: 'ar',
    60: 'br',
    112: 'nl',
    149: 'de',
    199: 'it',
    348: 'pl',
    349: 'pt',
    364: 'ru',
    409: 'es',
    444: 'tr',
}

// !!!!!!!!!!! NEED TO UPDATE getDateWithTypeSite ON CHANGE (motherbase-justtheweb-web/src/services/site/mo_getDateWithType.ts) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE getScheduleSite ON CHANGE (motherbase-justtheweb-web/src/services/site/mo_getSchedule.ts) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE SEO ON CHANGE (motherbase--seo/src/seo/views/main.py) !!!!!!!!!!!

// !!!!!!!!!!! NEED TO UPDATE TEXT (https://api.justtheweb.io/en/admin/main/text/?components__id__exact=1020) !!!!!!!!!!!
