// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesTicketSite,
} from 'data'

// props
type BillingHelper816SiteSiteProps = {
    test?: string
}

// main
export const BillingHelper816SiteSite: React.FC<BillingHelper816SiteSiteProps> = React.memo(({
    test,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const email = reduxSalesSite.ticketData?.contact_info?.email || ''
    const first_name = reduxSalesSite.ticketData?.contact_info?.first_name || ''
    const last_name = reduxSalesSite.ticketData?.contact_info?.last_name || ''

    useEffect(() => {
        if (!reduxSalesSite.ticketData?.contact_info?.email) {
            dispatch(reduxSalesTicketSite({
                ...reduxSalesSite.ticketData,
                contact_info: {
                    ...reduxSalesSite.ticketData?.contact_info,
                    email: reduxAuth?.settings?.activity_parent?.email || '',
                    first_name: reduxAuth?.settings?.activity_parent?.first_name || '',
                    last_name: reduxAuth?.settings?.activity_parent?.last_name || '',
                }
            }))
        }
    }, [])

    function onSetContactInfo(value: any) {
        try {
            const toReturnData = {
                ...reduxSalesSite.ticketData,
                contact_info: {
                    ...reduxSalesSite.ticketData?.contact_info,
                    [value.target.name]: value.target.value,
                },
            }
            // @ts-ignore
            dispatch(reduxSalesTicketSite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'BillingHelper816SiteSite',
                'onSetContactInfo',
            ))
        }
    }

    return (
        <div className={`t-816-contact-info ${deviceType}`}>
            <span className={`t-816-contact-info-title ${deviceType}`}>{reduxText[8743]}</span>
            <div className={`t-816-contact-info-inputs ${deviceType}`}>
                <div className={`t-816-contact-info-input-wrap ${deviceType}`}>
                    <span className={`t-816-contact-info-label ${deviceType}`}>{reduxText[8744]}</span>
                    <input
                        className={`t-816-contact-info-input ${deviceType}`}
                        name='first_name'
                        onChange={(e) => onSetContactInfo(e)}
                        value={first_name}
                    />
                </div>
                <div className={`t-816-contact-info-input-wrap ${deviceType}`}>
                    <span className={`t-816-contact-info-label ${deviceType}`}>{reduxText[8745]}</span>
                    <input
                        className={`t-816-contact-info-input ${deviceType}`}
                        name='last_name'
                        onChange={(e) => onSetContactInfo(e)}
                        value={last_name}
                    />
                </div>
            </div>
            <div className={`t-816-contact-info-email-input-wrap ${deviceType}`}>
                <span className={`t-816-contact-info-label ${deviceType}`}>{reduxText[8746]}</span>
                <input
                    className={`t-816-contact-info-input ${deviceType}`}
                    name='email'
                    onChange={(e) => onSetContactInfo(e)}
                    value={email}
                />
            </div>
        </div>
    )
})
