// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import L from 'leaflet'
import {
    FeatureGroup,
    Map,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    api_url_portfolio_hub_content_map_site,
    defaultReduxState,
    leaflet_attribution_mapbox_site,
    leaflet_url_mapbox_light_site,
    media_url_site,
    media_version_site,
    reduxModalClearSearchSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    PrivateMapListSite,
} from 'pages'

// serializers
import {
    MainMapSerializer,
    PortfolioHubContentFilterListSiteSerializer,
} from 'serializers/site'

// services
import {
    getApiUrlSite,
    getMapListSite,
    parseQuerySite,
    stringifyQuery,
} from 'services'

// props
type PrivateMapSiteProps = {
    devId: string | undefined
    filterInfo: {
        filterSet: PortfolioHubContentFilterListSiteSerializer['filters'],
        tabSlug: string
    } | undefined
    tabSlug: string
}

// main
export const PrivateMapSite: React.FC<PrivateMapSiteProps> = React.memo(({
    devId,
    filterInfo,
    tabSlug,
}) => {

    const dispatch = useDispatch()

    const history = useHistory()
    const location = useLocation()

    const clusterRef = useRef(null)
    const groupRef = useRef<FeatureGroup>(null)
    const mapRef = useRef<Map>(null)

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource>()
    const [clusterIds, setClusterIds] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isInit, setIsInit] = useState(false)
    const [searchClearNumber, setSearchClearNumber] = useState<number | undefined>(reduxModalSite.searchClear)

    const customMarker = L.icon({
        iconUrl: `${media_url_site}global/map/marker.svg${media_version_site}`,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
    })

    useEffect(() => {
        onGetMapData()
        setIsInit(true)
    }, [
        tabSlug,
    ])

    useEffect(() => {
        if (reduxCacheSite.portfolio && isInit) {
            const parsed = parseQuerySite(location.search)
            if ((reduxModalSite.searchClear !== searchClearNumber) || parsed.query || parsed.filter_query) {
                onGetMapData()
                setSearchClearNumber(reduxModalSite.searchClear)
            }
        }
    }, [
        location.search,
        reduxModalSite.searchClear,
    ])

    function addMarkers(data: MainMapSerializer[]) {
        try {
            const markers = L.markerClusterGroup()
            let hasMarkers = false
            data.filter(obj => obj.lat !== undefined).map((val) => {
                hasMarkers = true
                const marker = L.marker(
                    [val.lat, val.lng],
                    {
                        icon: customMarker,
                    },
                ).on(
                    'click',
                    () => handleMarkersClick(val),
                )
                // @ts-ignore
                marker.mo_id = val.parent
                markers.addLayer(marker)
                return false
            })
            // @ts-ignore
            clusterRef?.current?.leafletElement?.addLayers(markers)
            if (hasMarkers) handleFitBounds(data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateMapSite',
                'addMarkers',
            ))
        }
    }

    function handleFitBounds(data: MainMapSerializer[]) {
        try {
            if (data.length > 0) {
                const map = mapRef?.current?.leafletElement
                const group = groupRef?.current?.leafletElement
                if (group) map?.fitBounds(group?.getBounds())
            }
        } catch (error) {
            // temp
            // dispatch(reduxModalErrorEventHandlerSite(
            //     error,
            //     'PrivateMapSite',
            //     'handleFitBounds',
            // ))
        }
    }

    function handleMarkersClick(marker: MainMapSerializer) {
        try {
            if (['is-mobile', 'is-tablet'].includes(deviceType)) {
                history.push(`${reduxModalSite.rootUrl || '/'}${marker.pathname}/${marker.parent}`)
            } else {
                setClusterIds([marker.parent])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateMapSite',
                'handleMarkersClick',
            ))
        }
    }

    function handleMarkerClusterClick(cluster: any) {
        try {
            const idArray: number[] = []
            cluster.layer.getAllChildMarkers().map((val: any) => {
                idArray.push(Number(val.mo_id))
                return false
            })
            setClusterIds(idArray)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateMapSite',
                'handleMarkerClusterClick',
            ))
        }
    }

    function onFilterChange(filterString: string) {
        try {
            const searchObject = parseQuerySite(location.search)
            searchObject.filter_query = filterString
            if (!filterString || (filterString === '&')) {
                dispatch(reduxModalClearSearchSite())
            }
            history.push(`${location.pathname}?${stringifyQuery(searchObject)}`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivatePageSite',
                'onSearch',
            ))
        }
    }

    function onGetMapData() {
        try {
            if (clusterRef?.current) {
                // @ts-ignore
                clusterRef.current?.leafletElement.clearLayers()
            }
            const apiUrl = getApiUrlSite(`${api_url_portfolio_hub_content_map_site}${tabSlug}/?portfolio_id=${reduxCacheSite.portfolio?.id}`, reduxModalSite)
            getMapListSite({
                addMarkers,
                apiUrl,
                axiosCancelToken,
                component: 'PrivateMapSite',
                dispatch,
                // @ts-ignore
                location,
                reduxAuth,
                reduxModalSite,
                setAxiosCancelToken,
                setClusterIds,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PrivateMapSite',
                'getListData',
            ))
        }
    }

    return (
        <div
            className={`private-map-site ${deviceType}`}
            style={{
                height: `calc(100vh - ${reduxModalSite.navbarHeight}px - ${['is-mobile', 'is-tablet'].includes(deviceType) ? 0 : 50}px)`,
            }}
        >
            <LoaderSite isOpen={isLoading} message={process.env.NODE_ENV === 'development' ? 'PrivateMapSite' : ''} />
            <div className={`hms-map ${deviceType}`}>
                {!isLoading && (
                    <Map
                        boxZoom
                        center={{ lat: 0, lng: 0 }}
                        minZoom={1}
                        preferCanvas
                        ref={mapRef}
                        zoom={2}
                        zoomControl={deviceType === 'is-web'}
                    >
                        <TileLayer
                            attribution={leaflet_attribution_mapbox_site}
                            tileSize={512}
                            url={leaflet_url_mapbox_light_site}
                            zoomOffset={-1}
                        />
                        <FeatureGroup ref={groupRef}>
                            <MarkerClusterGroup
                                chunkedLoading
                                maxClusterRadius={40}
                                onClusterClick={(cluster: any) => handleMarkerClusterClick(cluster)}
                                onMarkerClick={(marker: MainMapSerializer) => handleMarkersClick(marker)}
                                ref={clusterRef}
                                removeOutsideVisibleBounds
                            />
                        </FeatureGroup>
                    </Map>
                )}
            </div>
            {deviceType === 'is-web' && (
                <PrivateMapListSite
                    clusterIds={clusterIds}
                    devId={devId}
                    filterInfo={filterInfo}
                    onFilterChange={onFilterChange}
                    tabSlug={tabSlug}
                />
            )}
        </div>
    )
})
